import { useMemo } from 'react';
import { Skeleton, Space } from 'antd';
import type { ColumnsType } from 'antd/es/table';

import { useEngineeringToolUsage } from '../hooks/useEngineeringToolUsage';
import { useEngineeringToolVersions } from '../hooks/useEngineeringToolVersions';
import { SemanticVersion } from '../../../domain/versioning/semanticVersion';
import { LatestTag } from '../../shared/components/LatestTag';
import { Comparator } from '../../../domain/extensions/comparison';
import { Tool, ToolUsage, ToolVersion } from '../../../api/engineering/domain/types';
import Table from '../../../contexts/shared/components/Table/Table';
import { MetricOnVisible } from '../../../contexts/metrics/components/MetricOnVisible';
import { MetricViewIds } from '../../../contexts/metrics/constants';
import { toolToTags } from '../../../contexts/metrics/utils/mapping';
import { useProjects } from '../../../contexts/projects/hooks/useProjects';
import { InfoTooltip } from '../../shared/components/InfoTooltip';
import { EngineeringToolVersionVulnerabilityButton } from './EngineeringToolVersionVulnerabilityButton';

export const EngineeringToolUsageList = (props: { engineeringTool: Tool }) => {
  const projects = useProjects();
  const versions = useEngineeringToolVersions(props.engineeringTool.id?.toString() || '');

  const usage = useEngineeringToolUsage(props.engineeringTool.id?.toString() || '');
  const sortedUsage: ToolUsage[] = useMemo(() => {
    if (projects.data) {
      return [...(usage.data || [])].sort((a, b) => {
        const projectAName = projects.data.find((x) => x.idProject === a.idProject)?.name || '';
        const projectBName = projects.data.find((x) => x.idProject === b.idProject)?.name || '';
        return Comparator.lexicographicalComparison(projectAName, projectBName);
      });
    }
    return [];
  }, [usage.data, projects.data]);

  const loading = !(usage.isSuccess && projects.isSuccess && versions.isSuccess);

  const columns: ColumnsType<ToolUsage> = [
    {
      title: (
        <>
          Projects /
          <br />
          <Space>
            Tool versions
            <InfoTooltip text="Only used tool versions are visible in this table." />
          </Space>
        </>
      ),
      key: 'project',
      width: '280px',
      fixed: true,
      render: (record: ToolUsage) => {
        return (
          <>
            <Space style={{ justifyContent: 'space-between', width: '100%' }}>{projects.data?.find((x) => x.idProject === record.idProject)?.name}</Space>
          </>
        );
      }
    }
  ];

  if (usage.data && projects.data && versions.data) {
    const toolVersionIds = new Set<number>();
    sortedUsage.forEach((project) => {
      project.configurations.forEach((config) => {
        toolVersionIds.add(config.idToolVersion);
      });
    });

    const sortedVersionIds = Array.from(toolVersionIds)
      .sort((a, b) => {
        if (a === props.engineeringTool.latestVersion?.idToolVersion) {
          return -1;
        }
        if (b === props.engineeringTool.latestVersion?.idToolVersion) {
          return 1;
        }
        return SemanticVersion.sorter(
          versions.data.find((x) => x.idToolVersion === a)?.version || '',
          versions.data.find((x) => x.idToolVersion === b)?.version || ''
        );
      })
      .map((x) => versions.data.find((d) => d.idToolVersion === x))
      .filter(Boolean) as ToolVersion[];

    sortedVersionIds.forEach((version) => {
      columns.push({
        title: (
          <Space style={{ minWidth: 170, maxWidth: 280 }}>
            {version.version}
            {props.engineeringTool.latestVersion.idToolVersion === version.idToolVersion && <LatestTag />}
            <EngineeringToolVersionVulnerabilityButton tool={props.engineeringTool} version={version} />
          </Space>
        ),
        key: version.version,
        render: (record: ToolUsage) => {
          return (
            <div style={{ minWidth: 170, maxWidth: 280 }}>
              {record.configurations
                ?.filter((c) => c.idToolVersion === version.idToolVersion)
                .map((c) => c.bundleConfigName)
                .join(', ')}
            </div>
          );
        }
      });
    });
  }

  return (
    <Skeleton loading={loading}>
      <MetricOnVisible view={MetricViewIds.toolsProjectIntegration} payload={toolToTags(props.engineeringTool)} />
      <Table
        columns={columns}
        scroll={{ x: 'max-content' }}
        rowKey={(record: ToolUsage) => record.idProject?.toString() || ''}
        dataSource={sortedUsage}
        sticky={{
          offsetHeader: -25 // make the table header stick just below the drawer title
        }}
        pagination={false}
      />
    </Skeleton>
  );
};
