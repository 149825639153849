import { useState, useEffect, useRef, useMemo } from 'react';
import { Select, Form, Col, Row } from 'antd';
import { useBundleConfigurationVersionDropdownData } from '../hooks/useBundleConfigurationVersionDropdownData';
import { useSearchParameter } from '../../navigation/hooks/useSearchParameter';
import { DirtyTag } from './DirtyTag';
import { SemanticVersion } from '../../../domain';
import { BundleConfigurationSelection } from '../../../domain/bundleConfigurationSelection';
import { useDebounce } from '../../../contexts/shared/hooks/useDebounce';
import { StyledRow } from '../../shared/base/Components.styled';
import styled from 'styled-components';

export const bundleLabel = (text: string) => {
  return <div style={{ width: 100 }}>{text}</div>;
};

export type BundleConfigVersionProps = {
  projectId: string;
  bundleId: string;
  selected?: BundleConfigurationSelection;
  disableLabel?: boolean;
  isDirty?: boolean;
  hideConfiguration?: boolean;
  disableDropdown?: boolean;
  hideEmptyVesions?: boolean;
  onSelect: (newConfig: BundleConfigurationSelection) => void;
  configurationDropdownExtensions?: React.ReactNode[];
  configurationVersionDropdownExtensions?: React.ReactNode[];
  id: string;
  layout?: 'horizontal' | 'vertical';
  // onLoading?: (loading: boolean) => void;
};

const StyledFormItem = styled(Form.Item)<{ isFirst?: boolean }>`
  margin-bottom: 0 !important;
  width: 120px !important;

  ${({ isFirst }) =>
    !isFirst
      ? `
    margin-left: 8px !important;
  `
      : ''}
`;

const filterExpandableMenuItemWithoutChildren = (element: any) => {
  if (!element) {
    return false;
  }
  // No children, different component, return
  if (!element.props.children) {
    return true;
  }
  // Check that at least one of the children is valid
  const asComponentArray = element.props.children as any[];
  return asComponentArray.some(Boolean);
};

export const BundleConfigurationVersionDropdown = (props: BundleConfigVersionProps) => {
  const configSearchParam = `${props.id}_config`;
  const versionSearchParam = `${props.id}_version`;
  const actualLayout = props.layout || 'vertical';
  const hideConfiguration = !!props.hideConfiguration;
  const disableDropdown = !!props.disableDropdown;
  const hideEmptyVesions = !!props.hideEmptyVesions;
  const [searchBundleConfigId, setSearchBundleConfigId] = useSearchParameter(configSearchParam);
  const [searchBundleVersionId, setSearchBundleVersionId] = useSearchParameter(versionSearchParam);

  const givenConfigId = useMemo(() => props.selected?.bundleConfigurationId || searchBundleConfigId || '', [props.selected, searchBundleConfigId]);
  const givenConfigReleaseId = useMemo(
    () => props.selected?.bundleConfigurationVersionId || searchBundleVersionId || '',
    [props.selected, searchBundleVersionId]
  );

  const [searchValue, setSearchValue] = useState({
    projectId: props.projectId,
    bundleId: props.bundleId,
    configId: givenConfigId,
    configReleaseId: givenConfigReleaseId
  });

  const debouncedSearchValue = useDebounce(searchValue, 200);

  const searchValueRef = useRef(debouncedSearchValue);

  const [lastEmitted, setLastEmitted] = useState<BundleConfigurationSelection | undefined>(undefined);

  const data = useBundleConfigurationVersionDropdownData(
    debouncedSearchValue.projectId,
    debouncedSearchValue.bundleId,
    debouncedSearchValue.configId,
    debouncedSearchValue.configReleaseId
  );
  const configurationExtensions = props.configurationDropdownExtensions?.filter(filterExpandableMenuItemWithoutChildren) || [];
  const configurationVersionExtensions = props.configurationVersionDropdownExtensions?.filter(filterExpandableMenuItemWithoutChildren) || [];

  useEffect(() => {
    searchValueRef.current = { ...debouncedSearchValue };
    setSearchBundleConfigId(debouncedSearchValue.configId);
    setSearchBundleVersionId(debouncedSearchValue.configReleaseId);
  }, [debouncedSearchValue, setSearchBundleConfigId, setSearchBundleVersionId]);

  useEffect(() => {
    if (!data.data) return;
    const value = {
      bundleConfigurationId: data.data.bundleConfigId,
      bundleConfigurationName: data.data.bundleConfigName,
      bundleConfigurationVersionId: data.data.bundleConfigReleaseId,
      bundleConfigurationVersionName: data.data.bundleConfigReleaseVersion,
      bundleId: data.data.bundleId,
      projectId: data.data.projectId
    };
    if (
      lastEmitted?.projectId === value.projectId &&
      lastEmitted.bundleId === value.bundleId &&
      lastEmitted?.bundleConfigurationId === value.bundleConfigurationId &&
      lastEmitted.bundleConfigurationVersionId === value.bundleConfigurationVersionId
    ) {
      return;
    }
    setSearchValue({
      bundleId: data.data.bundleId,
      configId: data.data.bundleConfigId,
      configReleaseId: data.data.bundleConfigReleaseId,
      projectId: data.data.projectId
    });

    setLastEmitted(value);
    props.onSelect(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.data, setSearchValue, lastEmitted, props.onSelect]);

  // eslint-disable-next-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (props.projectId === '' || props.bundleId === '') return;
    // use search value ref to not retrigger effect when search value changes
    if (
      props.projectId !== searchValueRef.current?.projectId ||
      props.bundleId !== searchValueRef.current?.bundleId ||
      givenConfigId !== searchValueRef.current?.configId ||
      givenConfigReleaseId !== searchValueRef.current?.configReleaseId
    ) {
      setSearchValue({
        bundleId: props.bundleId,
        projectId: props.projectId,
        configId: givenConfigId,
        configReleaseId: givenConfigReleaseId
      });
    }
  }, [props.projectId, props.bundleId, givenConfigId, givenConfigReleaseId, setSearchValue]);

  const bundleRowWidth = '250px';

  const selectStyle = {
    width: '100%',
    flex: 'auto'
  } as any;

  const configToShow = data.data?.bundleConfigId || '';
  const versionToShow = data.data?.bundleConfigReleaseId || '';
  const selectedConfigName = data.data?.bundleConfigName || 'unknown';
  const isLoading = data.isLoading;

  const configSelection = disableDropdown ? (
    selectedConfigName
  ) : (
    <Select
      className={`bundle-config-selector-${props.projectId}-${props.bundleId}`}
      value={configToShow}
      loading={isLoading}
      onSelect={(val: any) => {
        // Remove id refference of last config if existing
        window.history.replaceState({}, document.title, window.location.pathname + window.location.search);

        setSearchValue({
          ...searchValue,
          configId: val.toString()
        });
      }}
      showSearch
      optionFilterProp="children"
      style={selectStyle}
      filterOption={(input, option) => (option?.label?.toString() || '').toLowerCase().indexOf(input.toLowerCase()) >= 0}
      options={
        (data.data?.bundleConfigurations &&
          data.data.bundleConfigurations.map((conf: any) => {
            return { value: `${conf.idBundleConfiguration}`, label: conf.name || 'unknown' };
          })) ||
        []
      }
    />
  );

  const versionSelection = (
    <Select
      value={versionToShow}
      className={`bundle-config-version-selector-${props.projectId}-${props.bundleId}-${configToShow}`}
      loading={isLoading}
      onSelect={(val: any) => {
        // Remove id refference of last config if existing
        window.history.replaceState({}, document.title, window.location.pathname + window.location.search);

        setSearchValue({
          ...searchValue,
          configReleaseId: val.toString()
        });
      }}
      showSearch
      optionFilterProp="children"
      filterOption={(input, option) => (option?.title?.toString() || '').toLowerCase().indexOf(input.toLowerCase()) >= 0}
      getPopupContainer={(trigger) => trigger.parentNode}
      style={selectStyle}
      options={
        (data.data?.bundleReleases &&
          data.data.bundleReleases
            .sort((a, b) => SemanticVersion.fromString(a.version).compareTo(SemanticVersion.fromString(b.version)))
            .map((conf) => {
              return {
                value: `${conf.idBundleRelease}`,
                title: conf.version || 'unknown',
                label: (
                  <>
                    {/* {latestRelease === conf.idBundleRelease && <LatestTag />} */}
                    {props.isDirty && conf.idBundleRelease?.toString() === lastEmitted?.bundleConfigurationVersionId && <DirtyTag />}
                    {conf.version || 'unknown'}
                  </>
                )
              };
            })) ||
        []
      }
    />
  );

  return (
    <>
      {actualLayout === 'vertical' && (
        <Form layout="horizontal">
          {!hideConfiguration && (
            <Form.Item label={props.disableLabel ? '' : bundleLabel('Configuration')}>
              <StyledRow gutter={8} width={bundleRowWidth} wrap={false}>
                <Col flex="auto">{configSelection}</Col>
                {configurationExtensions.map((e, i) => (
                  <Col key={`conf${i}`}>{e}</Col>
                ))}
              </StyledRow>
            </Form.Item>
          )}

          {(!data.isLoading && data?.data?.bundleReleases.length) || !hideEmptyVesions ? (
            <Form.Item label={props.disableLabel ? '' : bundleLabel('Version')}>
              <StyledRow gutter={8} width={bundleRowWidth} wrap={false}>
                <Col flex="auto">{versionSelection}</Col>
                {configurationVersionExtensions.map((e, i) => (
                  <Col key={`vers${i}`}>{e}</Col>
                ))}
              </StyledRow>
            </Form.Item>
          ) : null}
        </Form>
      )}
      {actualLayout === 'horizontal' && (
        <Form layout="horizontal">
          <Row wrap={false}>
            {!hideConfiguration && (
              <StyledFormItem isFirst label={props.disableLabel ? '' : bundleLabel('Configuration')}>
                <Col flex="200px">{configSelection}</Col>
                {configurationExtensions.map((e, i) => (
                  <Col key={`conf${i}`}>{e}</Col>
                ))}
              </StyledFormItem>
            )}
            <StyledFormItem label={props.disableLabel ? '' : bundleLabel('Version')}>
              <Col flex="200px">{versionSelection}</Col>
              {configurationVersionExtensions.map((e, i) => (
                <Col key={`vers${i}`}>{e}</Col>
              ))}
            </StyledFormItem>
          </Row>
        </Form>
      )}
    </>
  );
};
