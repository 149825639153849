import { useParams } from 'react-router-dom';

import { useProject } from '../hooks/useProject';

import ProjectContentWrapper from './ProjectContentWrapper';
import { ProjectMemberList } from './ProjectMemberList';
import { useSearchParameter } from '../../../contexts/navigation/hooks';

const ProjectMembersPage = () => {
  const { projectId } = useParams() as { projectId: string };
  const project = useProject(projectId);
  const [projectType] = useSearchParameter('type');
  const projectLoaded = !project.isLoading && project.data;
  const breadcrumbItems = [{ title: project.data?.name, url: `/projects?active=${projectId}&type=${projectType}` }, { title: 'Members' }];

  return <ProjectContentWrapper breadcrumbItems={breadcrumbItems}>{projectLoaded && <ProjectMemberList project={project.data} />}</ProjectContentWrapper>;
};

export default ProjectMembersPage;
