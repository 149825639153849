import { Button, Tooltip } from 'antd';

import { useCurrentUser, usePermissions } from '../../session';
import { useProjectMembers } from '../hooks';
import { useCreateProjectAccessRequest } from '../hooks/useCreateProjectAccessRequest';
import { useProjectAccessRequest } from '../hooks/useProjectAccessRequest';

import type { Project } from '../../../api';


export const ProjectJoinButton = (props: { project: Project }) => {
  const accessRequest = useProjectAccessRequest(props.project.idProject);
  const joinProject = useCreateProjectAccessRequest();
  const permissions = usePermissions({ projectId: props.project.idProject.toString() });

  const hasPermissions = permissions.engineeringSvc$getProjectMemberAccessRequest && permissions.engineeringSvc$addProjectMemberAccessRequest;
  const user = useCurrentUser();
  const members = useProjectMembers(props.project.idProject.toString());
  const myself = members.data?.find((m) => m.member.idMember === user.id);

  const isLoading = !accessRequest.isSuccess || !members.isSuccess;

  const canRequestAccess = !isLoading && !myself && !accessRequest.data;
  const hasRequestedAccess = !!accessRequest.data;

  if (!hasPermissions) {
    return null;
  }

  if (isLoading) {
    return null;
  }

  if (canRequestAccess || hasRequestedAccess) {
    return (
      <Tooltip title="Joining the project will give you access to the project's resources.">
        <Button type="primary" loading={isLoading} onClick={() => joinProject.mutate(props.project)} disabled={hasRequestedAccess}>
          {hasRequestedAccess ? 'Join request sent' : 'Join Project'}
        </Button>
      </Tooltip>
    );
  }

  return null;
};
