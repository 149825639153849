import { useState, useEffect } from 'react';
import { Button, Form, Input, Tooltip } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { usePermissions } from '../../session/hooks/usePermissions';
import { ExpandableMenuItem } from '../../shared/components/ExpandableMenuItem';
import { useCreateBundleConfiguration } from '../hooks/useCreateBundleConfiguration';
import { BundleConfiguration } from '../../../api/engineering/domain/types';
import { DescriptionValidator, NameValidator } from '../../../domain';
import { ShiftedDrawer } from '../../shared/components/ShiftedDrawer';
import { DescriptionTextAreaInput } from '../../shared/components/DescriptionTextAreaInput';

export type AddBundleConfigurationProps = {
  projectId: string;
  bundleId: string;
  type: 'default' | 'button';
  disabled?: boolean;
  onAddBundleConfiguration?: (bundleConfiguration: BundleConfiguration) => any;
};

const initialBundleConfig: BundleConfiguration = {
  idBundleConfiguration: 0,
  name: '',
  description: '',
  latestBundleReleaseId: -1
};

export const AddBundleConfiguration = (props: AddBundleConfigurationProps) => {
  const permissions = usePermissions({ projectId: props.projectId });
  const buttonType = props.type || 'default';
  const [modalVisible, setModalVisible] = useState(false);
  const createBundleConfiguration = useCreateBundleConfiguration();
  const [form] = Form.useForm();

  const onSubmit = () => {
    form.submit();
  };

  useEffect(() => {
    if (createBundleConfiguration.isSuccess) {
      setModalVisible(false);
    }
  }, [createBundleConfiguration.isSuccess, form]);

  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 }
  };

  const AddConfigurationButtonJSX =
    buttonType === 'button' ? (
      <Button loading={createBundleConfiguration.isLoading} onClick={() => setModalVisible(true)} type="primary">
        New configuration
      </Button>
    ) : (
      <Tooltip title="Add Bundle Configuration">
        <ExpandableMenuItem
          icon={<PlusOutlined />}
          loading={createBundleConfiguration.isLoading}
          onClick={() => {
            setModalVisible(true);
          }}
        >
          Add Bundle Configuration
        </ExpandableMenuItem>
      </Tooltip>
    );

  return (
    <>
      {permissions.engineeringSvc$addProjectBundleConfiguration && AddConfigurationButtonJSX}

      <ShiftedDrawer
        destroyOnClose
        title="Add Bundle Configuration"
        loading={createBundleConfiguration.isLoading}
        isFormDrawer
        open={modalVisible}
        onOk={onSubmit}
        onCancel={() => {
          setModalVisible(false);
        }}
      >
        <Form
          {...layout}
          name="basic"
          labelAlign="left"
          form={form}
          initialValues={() => {
            return { ...initialBundleConfig };
          }}
          onFinish={(value) => {
            const copy = Object.assign(initialBundleConfig, value);
            createBundleConfiguration.mutateAsync([props.projectId, props.bundleId, copy]).then((bc: any) => {
              if (props.onAddBundleConfiguration && bc) {
                props.onAddBundleConfiguration(bc);
              }
            });
          }}
        >
          <Form.Item label="Name" name="name" rules={[NameValidator.rule()]}>
            <Input />
          </Form.Item>
          <Form.Item label="Description" name="description" rules={[DescriptionValidator.rule()]}>
            <DescriptionTextAreaInput />
          </Form.Item>
        </Form>
      </ShiftedDrawer>
    </>
  );
};
