import { useEffect, useState } from 'react';
import { Space } from 'antd';
import { InfoCircleOutlined, OrderedListOutlined } from '@ant-design/icons';
import { SubscriptionPopup } from '../../../contexts/notifications/components/SubscriptionPopup';
import { DownloadContextMenuButton } from '../../../contexts/shared/components/Download/components/DownloadContextMenuButton';
import { EngineeringToolsOpenInToolManagerButton } from './EngineeringToolsOpenInToolManagerButton';
import { EngineeringToolSelection } from '../../../domain';
import { usePermissions } from '../../../contexts/session/hooks/usePermissions';
import useSubmitMetric from '../../../contexts/metrics/hooks/useMetrics';
import { MetricClickIds } from '../../../contexts/metrics/constants';
import { EngineeringToolVersionsDrawer } from './EngineeringToolVersionsDrawer';
import { useSearchParameter } from '../../navigation/hooks/useSearchParameter';
import { EngineeringToolDetailsDrawer } from './EngineeringToolDetailsDrawer';

import type { Project, Tool } from '../../../api';
import { ExpandableMenu } from '../../../contexts/shared/components/ExpandableMenu';
import { ExpandableMenuItem } from '../../../contexts/shared/components/ExpandableMenuItem';

export const EngineeringToolActionsMenu = (props: { tool: Tool; project?: Project; selection?: EngineeringToolSelection }) => {
  const [openVersionDrawer, setOpenVersionDrawer] = useState(false);
  const [openDetailsDrawer, setOpenDetailsDrawer] = useState(false);
  const [openDrawerToolVersionIdParam, setOpenDrawerToolVersionIdParam] = useSearchParameter('open_tool_versions');
  const [openDrawerToolDetailsIdParam, setOpenDrawerToolDetailsIdParam] = useSearchParameter('open_tool_details');

  const permissions = usePermissions({
    projectId: props.project?.idProject.toString(),
    toolId: props.tool.id.toString(),
    idToolVersion: props.selection?.version.idToolVersion.toString()
  });
  const metrics = useSubmitMetric();
  const canGetVersions = permissions.engineeringSvc$getToolVersions || permissions.engineeringSvc$getToolVersion$specific().length > 0;

  useEffect(() => {
    // When loading a page check if the drawer should be open based on the search params.
    if (!openVersionDrawer && canGetVersions && openDrawerToolVersionIdParam && openDrawerToolVersionIdParam === props.tool.id.toString()) {
      setOpenVersionDrawer(true);
    }

    if (!openDetailsDrawer && openDrawerToolDetailsIdParam && openDrawerToolDetailsIdParam === props.tool.id.toString()) {
      setOpenDetailsDrawer(true);
    }
  }, [openVersionDrawer, openDetailsDrawer, openDrawerToolDetailsIdParam, canGetVersions, openDrawerToolVersionIdParam, props.tool.id]);

  const handleOpenVersionDrawer = () => {
    setOpenDrawerToolVersionIdParam(props.tool.id.toString());
    setOpenVersionDrawer(true);
  };

  const handleCloseVersionDrawer = () => {
    setOpenVersionDrawer(false);
    setOpenDrawerToolVersionIdParam('');
  };

  const handleOpenDetailsDrawer = () => {
    setOpenDrawerToolDetailsIdParam(props.tool.id.toString());
    setOpenDetailsDrawer(true);
  };

  const handleCloseDetailsDrawer = () => {
    setOpenDetailsDrawer(false);
    setOpenDrawerToolDetailsIdParam('');
  };

  return (
    <>
      <Space>
        <SubscriptionPopup type="text" title={props.tool.name} topics={[{ key: `tools/${props.tool.id}/**`, title: props.tool.name }]} />

        <ExpandableMenu textType id={`engineering-tool-actions-${props.tool.id}`}>
          {permissions.webui$showComponentDetails && (
            <ExpandableMenuItem
              icon={<InfoCircleOutlined />}
              onClick={() => {
                metrics
                  .submitClick({
                    operationId: MetricClickIds.componentDetails
                  })
                  .withToolContext(props.tool);

                handleOpenDetailsDrawer();
              }}
            >
              Details
            </ExpandableMenuItem>
          )}
          {canGetVersions && (
            <ExpandableMenuItem
              icon={<OrderedListOutlined />}
              onClick={() => {
                metrics
                  .submitClick({
                    operationId: MetricClickIds.componentVersions
                  })
                  .withToolContext(props.tool);

                handleOpenVersionDrawer();
              }}
            >
              Versions
            </ExpandableMenuItem>
          )}
          {props.selection && <DownloadContextMenuButton artifact={props.selection.engineeringTool} version={props.selection.version} />}
          {props.selection && <EngineeringToolsOpenInToolManagerButton selection={props.selection} project={props.project} />}
        </ExpandableMenu>
      </Space>

      {permissions.webui$showComponentDetails && <EngineeringToolDetailsDrawer onClose={handleCloseDetailsDrawer} open={openDetailsDrawer} tool={props.tool} />}

      {canGetVersions && <EngineeringToolVersionsDrawer onClose={handleCloseVersionDrawer} open={openVersionDrawer} tool={props.tool} />}
    </>
  );
};
