import styled from 'styled-components';
import { Typography } from 'antd';

export const ProjectSectionTitle = styled(Typography.Title)<{ smallWidth?: boolean; regular?: boolean }>`
  font-weight: 700 !important;
  font-size: ${({ theme }) => theme.fontSize}px !important;
  ${({ regular }) => (regular ? '' : 'border-bottom: 1px solid #C8C8C9;')}
  ${({ regular, smallWidth }) => {
    if (regular) return '';
    if (smallWidth) return 'width: 210px;';
    else return 'width: 385px;';
  }}
    ${({ regular }) => (regular ? '' : 'margin-bottom: 15px !important;')}
`;
