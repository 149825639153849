/* eslint-disable max-len */
import Icon from '@ant-design/icons';
import { SVGProps } from 'react';

export type PacTSTextIconProps = {
  style?: React.CSSProperties;
  isLoggedIn?: boolean;
  showDarkIcon?: boolean;
};

export const PacTSTextIcon: React.FunctionComponent<PacTSTextIconProps> = (props: PacTSTextIconProps) => {
  const style = {
    height: props.isLoggedIn ? '24px' : '36px'
  };

  const svg: React.FunctionComponent<SVGProps<SVGSVGElement>> = () => (
    <svg style={style} viewBox="0 0 860 256" fill="none" xmlns="http://www.w3.org/2000/svg">
      <title>PacTS</title>
      <path
        d="M262.315 123.667L201.956 63.308C201.312 62.6646 200.212 63.1203 200.212 64.0301V86.7151C200.212 87.2568 200.428 87.7763 200.811 88.1593L236.319 123.667C238.712 126.06 238.712 129.94 236.319 132.333L200.811 167.841C200.428 168.224 200.212 168.743 200.212 169.285V191.97C200.212 192.88 201.312 193.335 201.956 192.692L262.315 132.333C264.708 129.94 264.708 126.06 262.315 123.667Z"
        fill="#19D2FF"
      />
      <path
        d="M119.335 61.5678C124.12 56.782 131.88 56.782 136.665 61.5678L152.552 77.4545C157.338 82.2403 157.338 89.9997 152.552 94.7855L130.889 116.449C129.293 118.044 126.707 118.044 125.112 116.449L103.448 94.7855C98.662 89.9997 98.662 82.2403 103.448 77.4545L119.335 61.5678Z"
        fill="#19D2FF"
      />
      <path
        d="M161.218 103.45C166.004 98.6647 173.763 98.6647 178.549 103.45L194.435 119.337C199.221 124.123 199.221 131.882 194.435 136.668L178.549 152.555C173.763 157.341 166.004 157.341 161.218 152.555L139.554 130.891C137.959 129.296 137.959 126.709 139.554 125.114L161.218 103.45Z"
        fill="white"
      />
      <path
        d="M77.4513 103.45C82.2371 98.6647 89.9965 98.6647 94.7823 103.45L116.446 125.114C118.041 126.71 118.041 129.296 116.446 130.891L94.7823 152.555C89.9965 157.341 82.2371 157.341 77.4513 152.555L61.5646 136.668C56.7787 131.882 56.7787 124.123 61.5646 119.337L77.4513 103.45Z"
        fill="#CB75FF"
      />
      <path
        d="M126.556 138.112C127.353 137.314 128.647 137.314 129.444 138.112L152.552 161.22C157.338 166.006 157.338 173.765 152.552 178.551L136.665 194.437C131.88 199.223 124.12 199.223 119.335 194.437L103.448 178.551C98.662 173.765 98.662 166.006 103.448 161.22L126.556 138.112Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M151.108 246.428C138.346 259.191 117.654 259.191 104.892 246.428L9.57164 151.108C-3.19055 138.346 -3.19055 117.654 9.57164 104.892L104.892 9.57164C117.654 -3.19055 138.346 -3.19055 151.108 9.57164L187.214 45.6778C191.202 49.666 191.202 56.1322 187.214 60.1203C183.226 64.1085 176.76 64.1085 172.772 60.1203L136.665 24.0141C131.88 19.2283 124.12 19.2283 119.335 24.0141L24.0141 119.335C19.2283 124.12 19.2283 131.88 24.0141 136.665L119.335 231.986C124.12 236.772 131.88 236.772 136.665 231.986L172.772 195.88C176.76 191.891 183.226 191.891 187.214 195.88C191.202 199.868 191.202 206.334 187.214 210.322L151.108 246.428Z"
        fill="white"
      />
      <path
        d="M386.717 61.6713H314.11V80.6324H385.7C390.756 80.6324 394.394 81.7424 396.614 83.9622C398.834 86.182 399.943 89.8201 399.943 94.8764V111.895C399.943 116.951 398.834 120.59 396.614 122.809C394.394 125.029 390.756 126.139 385.7 126.139H333.071V98.1753H314.11V194.307H333.071V145.1H386.717C408.175 145.1 418.905 134.34 418.905 112.82V93.9515C418.905 72.4314 408.175 61.6713 386.717 61.6713Z"
        fill="white"
      />
      <path
        d="M440.437 142.195C439.474 144.444 438.992 146.885 438.992 149.519V175.345C438.992 177.979 439.474 180.452 440.437 182.765C441.465 185.078 442.814 187.101 444.485 188.836C446.219 190.506 448.243 191.855 450.556 192.883C452.868 193.847 455.31 194.329 457.879 194.329H489.677V175.345H464.137C460.681 175.345 457.879 172.543 457.879 169.087V155.777C457.879 152.321 460.681 149.519 464.137 149.519H500.575C504.031 149.519 506.833 152.321 506.833 155.777V194.329H525.817V110.684C525.817 108.05 525.303 105.576 524.275 103.264C523.311 100.951 521.962 98.9593 520.228 97.2889C518.557 95.5544 516.566 94.2052 514.253 93.2416C511.94 92.2137 509.467 91.6998 506.833 91.6998H451.241C446.633 91.6998 442.897 95.4355 442.897 100.044V110.684H502.661C504.965 110.684 506.833 112.552 506.833 114.856V126.363C506.833 128.667 504.965 130.535 502.661 130.535H457.879C455.31 130.535 452.868 131.049 450.556 132.077C448.243 133.04 446.219 134.39 444.485 136.124C442.814 137.859 441.465 139.882 440.437 142.195Z"
        fill="white"
      />
      <path
        d="M578.787 110.596C575.331 110.596 572.529 113.398 572.529 116.854V168.999C572.529 172.455 575.331 175.257 578.787 175.257H615.224C618.681 175.257 621.482 172.455 621.482 168.999V161.858H640.466V175.257C640.466 177.891 639.952 180.364 638.924 182.677C637.961 184.99 636.612 187.014 634.877 188.748C633.207 190.419 631.215 191.768 628.902 192.796C626.59 193.759 624.116 194.241 621.482 194.241H572.529C569.959 194.241 567.518 193.759 565.205 192.796C562.892 191.768 560.869 190.419 559.134 188.748C557.464 187.014 556.115 184.99 555.087 182.677C554.123 180.364 553.641 177.891 553.641 175.257V110.596C553.641 107.962 554.123 105.489 555.087 103.176C556.115 100.863 557.464 98.8717 559.134 97.2013C560.869 95.4668 562.892 94.1177 565.205 93.154C567.518 92.1261 569.959 91.6122 572.529 91.6122H621.482C624.116 91.6122 626.59 92.1261 628.902 93.154C631.215 94.1177 633.207 95.4668 634.877 97.2013C636.612 98.8717 637.961 100.863 638.924 103.176C639.952 105.489 640.466 107.962 640.466 110.596V123.995H621.482V116.854C621.482 113.398 618.681 110.596 615.224 110.596H578.787Z"
        fill="white"
      />
      <path d="M743.343 61.6713H638.206V80.6324H743.343V61.6713Z" fill="white" />
      <path d="M681.294 194.307H700.255V99.1619H681.294V194.307Z" fill="white" />
      <path
        d="M778.442 137.516C775.853 137.516 773.386 137.022 771.043 136.036C768.761 134.988 766.757 133.631 765.031 131.966C763.304 130.24 761.948 128.236 760.961 125.954C759.974 123.611 759.481 121.144 759.481 118.555V80.6324C759.481 78.0426 759.974 75.607 760.961 73.3255C761.948 70.9823 763.304 68.9783 765.031 67.3134C766.757 65.5869 768.761 64.2303 771.043 63.2437C773.386 62.1955 775.853 61.6713 778.442 61.6713H840.911C843.501 61.6713 845.936 62.1955 848.218 63.2437C850.561 64.2303 852.596 65.5869 854.323 67.3134C856.049 68.9783 857.406 70.9823 858.392 73.3255C859.379 75.607 859.872 78.0426 859.872 80.6324V97.7437H840.911V86.8904C840.911 83.4342 838.109 80.6324 834.653 80.6324H784.7C781.244 80.6324 778.442 83.4342 778.442 86.8904V112.297C778.442 115.753 781.244 118.555 784.7 118.555H840.911C843.501 118.555 845.936 119.048 848.218 120.035C850.561 121.021 852.596 122.378 854.323 124.104C856.049 125.831 857.406 127.866 858.392 130.209C859.379 132.49 859.872 134.926 859.872 137.516V175.345C859.872 177.935 859.379 180.402 858.392 182.745C857.406 185.026 856.049 187.03 854.323 188.757C852.596 190.484 850.561 191.84 848.218 192.827C845.936 193.813 843.501 194.307 840.911 194.307H776.592C774.003 194.307 771.536 193.813 769.193 192.827C766.911 191.84 764.907 190.484 763.181 188.757C761.454 187.03 760.098 185.026 759.111 182.745C758.125 180.402 757.631 177.935 757.631 175.345V158.327H776.592V169.088C776.592 172.544 779.394 175.345 782.85 175.345H834.653C838.109 175.345 840.911 172.544 840.911 169.088V143.774C840.911 140.318 838.109 137.516 834.653 137.516H778.442Z"
        fill="white"
      />
    </svg>
  );

  const svgDark: React.FunctionComponent<SVGProps<SVGSVGElement>> = () => (
    <svg style={style} viewBox="0 0 860 256" fill="none" xmlns="http://www.w3.org/2000/svg">
      <title>PacTS</title>
      <path
        d="M262.315 123.667L201.956 63.308C201.312 62.6646 200.212 63.1203 200.212 64.0301V86.7151C200.212 87.2568 200.428 87.7763 200.811 88.1593L236.319 123.667C238.712 126.06 238.712 129.94 236.319 132.333L200.811 167.841C200.428 168.224 200.212 168.743 200.212 169.285V191.97C200.212 192.88 201.312 193.335 201.956 192.692L262.315 132.333C264.708 129.94 264.708 126.06 262.315 123.667Z"
        fill="#19D2FF"
      />
      <path
        d="M119.335 61.5678C124.12 56.782 131.88 56.782 136.665 61.5678L152.552 77.4545C157.338 82.2403 157.338 89.9997 152.552 94.7855L130.889 116.449C129.293 118.044 126.707 118.044 125.112 116.449L103.448 94.7855C98.662 89.9997 98.662 82.2403 103.448 77.4545L119.335 61.5678Z"
        fill="#19D2FF"
      />
      <path
        d="M161.218 103.45C166.004 98.6647 173.763 98.6647 178.549 103.45L194.435 119.337C199.221 124.123 199.221 131.882 194.435 136.668L178.549 152.555C173.763 157.341 166.004 157.341 161.218 152.555L139.554 130.891C137.959 129.296 137.959 126.709 139.554 125.114L161.218 103.45Z"
        fill="#3C345B"
      />
      <path
        d="M77.4513 103.45C82.2371 98.6647 89.9965 98.6647 94.7823 103.45L116.446 125.114C118.041 126.71 118.041 129.296 116.446 130.891L94.7823 152.555C89.9965 157.341 82.2371 157.341 77.4513 152.555L61.5646 136.668C56.7787 131.882 56.7787 124.123 61.5646 119.337L77.4513 103.45Z"
        fill="#CB75FF"
      />
      <path
        d="M126.556 138.112C127.353 137.314 128.647 137.314 129.444 138.112L152.552 161.22C157.338 166.006 157.338 173.765 152.552 178.551L136.665 194.437C131.88 199.223 124.12 199.223 119.335 194.437L103.448 178.551C98.662 173.765 98.662 166.006 103.448 161.22L126.556 138.112Z"
        fill="#3C345B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M151.108 246.428C138.346 259.191 117.654 259.191 104.892 246.428L9.57164 151.108C-3.19055 138.346 -3.19055 117.654 9.57164 104.892L104.892 9.57164C117.654 -3.19055 138.346 -3.19055 151.108 9.57164L187.214 45.6778C191.202 49.666 191.202 56.1322 187.214 60.1203C183.226 64.1085 176.76 64.1085 172.772 60.1203L136.665 24.0141C131.88 19.2283 124.12 19.2283 119.335 24.0141L24.0141 119.335C19.2283 124.12 19.2283 131.88 24.0141 136.665L119.335 231.986C124.12 236.772 131.88 236.772 136.665 231.986L172.772 195.88C176.76 191.891 183.226 191.891 187.214 195.88C191.202 199.868 191.202 206.334 187.214 210.322L151.108 246.428Z"
        fill="#3C345B"
      />
      <path
        d="M386.717 61.6713H314.11V80.6324H385.7C390.756 80.6324 394.394 81.7424 396.614 83.9622C398.834 86.182 399.943 89.8201 399.943 94.8764V111.895C399.943 116.951 398.834 120.59 396.614 122.809C394.394 125.029 390.756 126.139 385.7 126.139H333.071V98.1753H314.11V194.307H333.071V145.1H386.717C408.175 145.1 418.905 134.34 418.905 112.82V93.9515C418.905 72.4314 408.175 61.6713 386.717 61.6713Z"
        fill="#3C345B"
      />
      <path
        d="M440.437 142.195C439.474 144.444 438.992 146.885 438.992 149.519V175.345C438.992 177.979 439.474 180.452 440.437 182.765C441.465 185.078 442.814 187.101 444.485 188.836C446.219 190.506 448.243 191.855 450.556 192.883C452.868 193.847 455.31 194.329 457.879 194.329H489.677V175.345H464.137C460.681 175.345 457.879 172.543 457.879 169.087V155.777C457.879 152.321 460.681 149.519 464.137 149.519H500.575C504.031 149.519 506.833 152.321 506.833 155.777V194.329H525.817V110.684C525.817 108.05 525.303 105.576 524.275 103.264C523.311 100.951 521.962 98.9593 520.228 97.2889C518.557 95.5544 516.566 94.2052 514.253 93.2416C511.94 92.2137 509.467 91.6998 506.833 91.6998H451.241C446.633 91.6998 442.897 95.4355 442.897 100.044V110.684H502.661C504.965 110.684 506.833 112.552 506.833 114.856V126.363C506.833 128.667 504.965 130.535 502.661 130.535H457.879C455.31 130.535 452.868 131.049 450.556 132.077C448.243 133.04 446.219 134.39 444.485 136.124C442.814 137.859 441.465 139.882 440.437 142.195Z"
        fill="#3C345B"
      />
      <path
        d="M578.787 110.596C575.331 110.596 572.529 113.398 572.529 116.854V168.999C572.529 172.455 575.331 175.257 578.787 175.257H615.224C618.681 175.257 621.482 172.455 621.482 168.999V161.858H640.466V175.257C640.466 177.891 639.952 180.364 638.924 182.677C637.961 184.99 636.612 187.014 634.877 188.748C633.207 190.419 631.215 191.768 628.902 192.796C626.59 193.759 624.116 194.241 621.482 194.241H572.529C569.959 194.241 567.518 193.759 565.205 192.796C562.892 191.768 560.869 190.419 559.134 188.748C557.464 187.014 556.115 184.99 555.087 182.677C554.123 180.364 553.641 177.891 553.641 175.257V110.596C553.641 107.962 554.123 105.489 555.087 103.176C556.115 100.863 557.464 98.8717 559.134 97.2013C560.869 95.4668 562.892 94.1177 565.205 93.154C567.518 92.1261 569.959 91.6122 572.529 91.6122H621.482C624.116 91.6122 626.59 92.1261 628.902 93.154C631.215 94.1177 633.207 95.4668 634.877 97.2013C636.612 98.8717 637.961 100.863 638.924 103.176C639.952 105.489 640.466 107.962 640.466 110.596V123.995H621.482V116.854C621.482 113.398 618.681 110.596 615.224 110.596H578.787Z"
        fill="#3C345B"
      />
      <path d="M743.343 61.6713H638.206V80.6324H743.343V61.6713Z" fill="#3C345B" />
      <path d="M681.294 194.307H700.255V99.1619H681.294V194.307Z" fill="#3C345B" />
      <path
        d="M778.442 137.516C775.853 137.516 773.386 137.022 771.043 136.036C768.761 134.988 766.757 133.631 765.031 131.966C763.304 130.24 761.948 128.236 760.961 125.954C759.974 123.611 759.481 121.144 759.481 118.555V80.6324C759.481 78.0426 759.974 75.607 760.961 73.3255C761.948 70.9823 763.304 68.9783 765.031 67.3134C766.757 65.5869 768.761 64.2303 771.043 63.2437C773.386 62.1955 775.853 61.6713 778.442 61.6713H840.911C843.501 61.6713 845.936 62.1955 848.218 63.2437C850.561 64.2303 852.596 65.5869 854.323 67.3134C856.049 68.9783 857.406 70.9823 858.392 73.3255C859.379 75.607 859.872 78.0426 859.872 80.6324V97.7437H840.911V86.8904C840.911 83.4342 838.109 80.6324 834.653 80.6324H784.7C781.244 80.6324 778.442 83.4342 778.442 86.8904V112.297C778.442 115.753 781.244 118.555 784.7 118.555H840.911C843.501 118.555 845.936 119.048 848.218 120.035C850.561 121.021 852.596 122.378 854.323 124.104C856.049 125.831 857.406 127.866 858.392 130.209C859.379 132.49 859.872 134.926 859.872 137.516V175.345C859.872 177.935 859.379 180.402 858.392 182.745C857.406 185.026 856.049 187.03 854.323 188.757C852.596 190.484 850.561 191.84 848.218 192.827C845.936 193.813 843.501 194.307 840.911 194.307H776.592C774.003 194.307 771.536 193.813 769.193 192.827C766.911 191.84 764.907 190.484 763.181 188.757C761.454 187.03 760.098 185.026 759.111 182.745C758.125 180.402 757.631 177.935 757.631 175.345V158.327H776.592V169.088C776.592 172.544 779.394 175.345 782.85 175.345H834.653C838.109 175.345 840.911 172.544 840.911 169.088V143.774C840.911 140.318 838.109 137.516 834.653 137.516H778.442Z"
        fill="#3C345B"
      />
    </svg>
  );

  return <Icon {...props} component={props.showDarkIcon ? svgDark : svg} />;
};
