import { useState, useEffect, useMemo } from 'react';
import { EditOutlined } from '@ant-design/icons';

import { usePermissions } from '../../session/hooks/usePermissions';
import { ExpandableMenuItem } from '../../shared/components/ExpandableMenuItem';
import { useUpdateProject } from '../hooks/useUpdateProject';
import { ProjectForm } from './ProjectForm';
import { ProjectUpdate } from '../../../api/engineering/domain/types';
import dayjs from 'dayjs';
import { ShiftedDrawer } from '../../shared/components/ShiftedDrawer';
import { Form } from 'antd';

export type UpdateProjectProps = {
  project: ProjectUpdate;
};

export const UpdateProject = (props: UpdateProjectProps) => {
  const [drawerVisible, setDrawerVisible] = useState(false);
  const updateProject = useUpdateProject();
  const permissions = usePermissions({ projectId: props.project.idProject.toString() });
  const [form] = Form.useForm();
  const initial = useMemo(() => {
    const copy = { ...props.project } as any;
    copy.startDate = dayjs(copy.startDate);
    copy.endDate = dayjs(copy.endDate);
    copy.projectType = copy.projectType.idProjectType;
    copy.projectSolutionType = copy.projectSolutionType.id;
    copy.projectPhase = copy.projectPhase.id;
    copy.referenceProjectRelease = copy.referenceProjectRelease ? { ...copy.referenceProjectRelease } : undefined;
    return copy;
  }, [props.project]);

  let okHandler: () => any;
  let resetHandler: () => any;

  useEffect(() => {
    if (updateProject.isSuccess) {
      setDrawerVisible(false);
    }
  }, [updateProject.isSuccess]);

  return (
    <>
      {permissions.engineeringSvc$updateProject && (
        <ExpandableMenuItem
          icon={<EditOutlined />}
          loading={updateProject.isLoading}
          onClick={() => {
            setDrawerVisible(true);
          }}
        >
          Edit
        </ExpandableMenuItem>
      )}

      <ShiftedDrawer
        title="Update Project"
        loading={updateProject.isLoading}
        open={drawerVisible}
        destroyOnClose
        isFormDrawer
        onOk={() => {
          okHandler();
        }}
        onCancel={() => {
          resetHandler();
          setDrawerVisible(false);
        }}
      >
        <ProjectForm
          initial={initial}
          ok={(ok) => {
            okHandler = ok;
          }}
          reset={(cancel) => {
            resetHandler = cancel;
          }}
          form={form}
          onFinish={async (ps) => {
            return new Promise((resolve, reject) => {
              updateProject
                .mutateAsync([ps as ProjectUpdate])
                .then(() => {
                  setDrawerVisible(false);
                  resolve();
                })
                .catch(() => {
                  reject();
                });
            });
          }}
        />
      </ShiftedDrawer>
    </>
  );
};
