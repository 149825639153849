import { Button, Col, Row } from 'antd';
import styled from 'styled-components';

import { ExternalTokenDialogContent } from './ExternalTokenDialogContent';
import { ScrollDialog } from '../layout/components/ScrollDialog';


const StyledCloseButton = styled(Button)`
  width: 100px !important;
`;

export const ExternalTokenDialog = (props: {
  open: boolean,
  setOpen: (open: boolean) => void
}) => {

  const handleCloseModal = () => {
    props.setOpen(false);
  };

  return (
    <ScrollDialog
      footer={
        <Row gutter={8} justify="end">
          <Col>
            <StyledCloseButton type="primary" onClick={handleCloseModal}>
              Close
            </StyledCloseButton>
          </Col>
        </Row>
      }
      width={868}
      title="Generate refresh token"
      open={props.open}
      onCancel={handleCloseModal}
      destroyOnClose
    >
      <ExternalTokenDialogContent />
    </ScrollDialog>
  );
};
