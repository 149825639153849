import { ColumnsType } from 'antd/es/table';
import { FlatDocument, FlatifiedResult } from '../hooks/useFlatifiedReports';
import { Space, Typography } from 'antd';
import { Comparator } from '../../../domain/extensions/comparison';
import { DocumentDetails } from './DocumentDetails';
import { OpenReportButton } from './OpenReportButton';
import { Project } from '../../../api';
import { RevisionMeta, SourceMeta } from '../../../api/report/domain/types';
import { documentId, formatValue, valueFromObjectPath } from '../shared/util';
import { ShareReportButton } from './ShareReportButton';
import Table from '../../../contexts/shared/components/Table/Table';
import { useSearchParameter } from '../../../contexts/navigation/hooks/useSearchParameter';
import { ReportTableTour } from './ReportTableTour';
import { useTableSearch } from '../../../contexts/shared/components/TableSearch';
import { useTableFilter } from '../../../contexts/shared/components/TableFilter';

/*
Note: This component is inherently unsafe!
In order to get dynamic search and filters work (which rely on components)
we need dynamic hook rendering.

To prevent react errors, make sure to use the digest of the flatified result as key for instances
of this component.

*/
export const ReportTable = (props: { project: Project; source: SourceMeta; revision: RevisionMeta; data: FlatifiedResult; columns: string[] }) => {
  const [tablePage, setTablePage] = useSearchParameter(`p`, '1');
  const [pageSize, setPageSize] = useSearchParameter(`ps`, '10');
  const tablePageNumber = parseInt(tablePage || '1');
  const pageSizeNumber = parseInt(pageSize || '10');

  const columns: ColumnsType<FlatDocument> = [
    ...(props.data.columns || []).map((column, index) => {
      let search: any;
      const searchParamId = column.id;

      // The first column is usually "name" due to the column sorting
      const firstColumn = index === 0;

      if (column.type === 'search') {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        search = useTableSearch({
          searchParamId,
          searchValueProvider: valueFromObjectPath(column.path)
        });
      }

      if (column.type === 'filter') {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        search = useTableFilter({
          searchParamId,
          values: (column.filterValues || []).map((v) => ({ text: v, value: v })),
          onFilter(data, filterVal) {
            const value = valueFromObjectPath<string>(column.path)(data);
            return value.includes(filterVal);
          },
          key: column.path.join('+')
        });
      }

      const defaultSortOrder = firstColumn ? 'ascend' : null;
      
      return {
        title: <Typography.Text style={{ whiteSpace: 'nowrap' }}>{column.name}</Typography.Text>,
        fixed: firstColumn ? 'left' : undefined,
        key: column.id,
        width: '250px',
        sorter: {
          compare: (a: FlatDocument, b: FlatDocument) =>
            Comparator.lexicographicalComparison(String(valueFromObjectPath<string>(column.path)(a)), String(valueFromObjectPath<string>(column.path)(b)))
        },
        defaultSortOrder,
        dataIndex: column.path,
        render: (val: any) => formatValue(val.toString()),
        ...search
      };
    }),
    {
      title: 'Actions',
      fixed: 'right',
      width: props.columns.length > 5 ? 200 : 'calc(13%)',
      key: 'actions',
      render: (doc: FlatDocument) => (
        <Space>
          <div id="report-tour-element2">
            <OpenReportButton key="open-view" document={doc} project={props.project} source={props.source} revision={props.revision} mode="web" />
          </div>
          <div id="report-tour-element5">
            <OpenReportButton key="open-download" document={doc} project={props.project} source={props.source} revision={props.revision} mode="download" />
          </div>
          <div id="report-tour-element3">
            <ShareReportButton key="share" document={doc} project={props.project} source={props.source} revision={props.revision} />
          </div>
          <DocumentDetails key="details" document={doc} columns={props.data.columns} revision={props.revision} source={props.source} />
        </Space>
      )
    }
  ];

  const actualColumns = columns.filter((c) => {
    const key = c.key?.toString() || '';
    if (key === 'report') return true;
    if (key === 'actions') return true;
    return props.columns.includes(key);
  });

  const table = (
    // TODO: change externalId to Id once available
    <>
      <Table
        key={props.data.digest}
        scroll={{ x: 'max-content' }}
        rowKey={documentId}
        columns={actualColumns}
        dataSource={props.data.documents}
        pagination={{
          showSizeChanger: true,
          current: tablePageNumber,
          onChange: (n) => setTablePage(n.toString()),
          defaultPageSize: pageSizeNumber,
          pageSizeOptions: ['10', '20', '50'],
          onShowSizeChange: (c, s) => {
            setPageSize(s.toString());
          }
        }}
      />
      <ReportTableTour />
    </>
  );

  return table;
};
