import { useCallback, useEffect, useRef, useState } from 'react';
import { Button } from 'antd';
import styled from 'styled-components';

import { useMutationObservable } from '../../shared/hooks/useMutationObservable';
import { MarkdownRenderer } from '../../../contexts/shared/components/MarkdownRenderer';

import type { Project } from '../../../api/engineering/domain/types';

const OverflowWrapper = styled.div<{ seeMore?: boolean }>`
  max-width: 900px;
  overflow: hidden;
  ${({ seeMore }) => (seeMore ? '' : 'max-height: 150px;')}
`;

const StyledButton = styled(Button)`
  cursor: pointer;
  padding: 0;
`;

export type Props = {
  project: Project;
};

export const ProjectDescription = (props: Props) => {
  const [visible, setVisible] = useState(false);
  const [overflowExisted, setOverflowExisted] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const markdownRef = useRef<HTMLDivElement>(null);

  const calculateOverflow = useCallback(() => {
    const el = markdownRef.current;
    setIsOverflowing(false);

    if (el && (el.clientHeight < el.scrollHeight || el.clientWidth < el.scrollWidth)) {
      setOverflowExisted(true);
      setIsOverflowing(true);
    }
  }, [setIsOverflowing, markdownRef]);

  // Initially calculate overflow
  useEffect(() => {
    calculateOverflow();
  }, [calculateOverflow]);

  useMutationObservable(markdownRef.current, calculateOverflow);

  const showHideMore = overflowExisted && visible;

  return (
    <>
      <OverflowWrapper seeMore={visible} ref={markdownRef}>
        <MarkdownRenderer maxImageWith="50%">{props.project.description}</MarkdownRenderer>
      </OverflowWrapper>

      {isOverflowing && (
        <StyledButton type="link" onClick={() => setVisible(true)}>
          see more...
        </StyledButton>
      )}

      {showHideMore && (
        <StyledButton type="link" onClick={() => setVisible(false)}>
          Hide more
        </StyledButton>
      )}
    </>
  );
};
