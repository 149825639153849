import { Space } from 'antd';
import { SoftwareAppSelection } from '../../../domain/softwareAppsSelections';
import { Comparator } from '../../../domain/extensions/comparison';
import { useTableSearch } from '../../shared/components/TableSearch';
import { ScopedSoftwareApp } from '../hooks/scopedSoftwareApp';
import { CommonSoftwareAppVersionDropdown } from './CommonSoftwareAppVersionDropdown';
import { ProjectSoftwareAppVersionDropdown } from './ProjectSoftwareAppVersionDropdown';
import { ProjectSoftwareAppsActionMenu } from './ProjectSoftwareAppsActionMenu';
import { CommonSoftwareAppsActionMenu } from './CommonSoftwareAppActionsMenu';
import { TableVersionHelp } from '../../shared/components/TableVersionHelp';
import { Project, SoftwareApp, SoftwareAppVersion } from '../../../api/engineering/domain/types';
import { useScrollInto } from '../../navigation/hooks/useScrollInto';
import React, { useState } from 'react';
import { scopedAppHash } from '../utils/scopedAppHash';
import Table from '../../../contexts/shared/components/Table/Table';
import { SegmentedLabeledOption, SegmentedValue } from 'antd/es/segmented';
import Segmented from '../../../contexts/shared/components/Segmented/Segmented';
import * as Styled from './SoftwareAppsSubTable.styled';
import { useSearchParameter } from '../../../contexts/navigation/hooks/useSearchParameter';
import styled from 'styled-components';

export type SelectionMap = {
  [versionId: string]: SoftwareAppSelection;
};

export const getSelectionMapKey = (swa: ScopedSoftwareApp) => {
  return `${swa.idSoftwareApp.toString()}-${swa.scope}`;
};

export const AppsTable = styled(Table)`
  .ant-table-body {
    overflow-y: auto !important;
    overflow-x: auto !important;
  }
` as typeof Table;

export const SoftwareAppsSubTable = React.memo(
  (props: {
    tabOptions: SegmentedLabeledOption[];
    activeTabKey: string;
    onActiveTabChange: ((value: SegmentedValue) => void) | undefined;
    project?: Project;
    updateSelectedSoftwareAppVersion: (sa: ScopedSoftwareApp, sv: SoftwareAppVersion) => void;
    selectionMap: SelectionMap;
    initialSelectionMap: SelectionMap;
    swApps: ScopedSoftwareApp[];
    hideCheckboxes?: boolean;
    hasTopOffset?: boolean;
    rowSelection: () => {
      selectedRowKeys: string[];
      onChange: (selectedRowKeys: any, selectedRows: ScopedSoftwareApp[]) => void;
      getCheckboxProps: (record: any) => {
        name: any;
      };
    };
  }) => {
    const nameSearch = useTableSearch({ searchValueProvider: 'name', searchParamId: `name` });
    const [tablePage, setTablePage] = useSearchParameter(`p_${props.activeTabKey}`, '1');
    const tablePageNumber = parseInt(tablePage || '1');
    const [pageSize, setPageSize] = useSearchParameter(`ps`, '10');
    const pageSizeNumber = parseInt(pageSize || '10');

    const [tableRef, setTableRef] = useState<any>();

    const scrollHash = location.hash?.replace('#', '.');
    useScrollInto(scrollHash, tableRef, tableRef);

    const columns = [
      {
        title: 'Name',
        key: 'name',
        fixed: 'left',
        ...nameSearch,
        sorter: (a: SoftwareApp, b: SoftwareApp) => Comparator.lexicographicalComparison(a.name, b.name),
        render: (a: ScopedSoftwareApp) => a.name
      },
      {
        title: <TableVersionHelp />,
        key: 'version',
        render: (swa: ScopedSoftwareApp) => {
          const selectedVersion = props.selectionMap[getSelectionMapKey(swa)]?.version;
          const initiallySelected = props.initialSelectionMap[getSelectionMapKey(swa)]?.version;
          return (
            <>
              {swa.scope === 'project' && props.project && (
                <ProjectSoftwareAppVersionDropdown
                  project={props.project}
                  softwareAppId={swa.idSoftwareApp!.toString()}
                  selectedVersion={selectedVersion}
                  initiallySelectedVersion={initiallySelected}
                  onSelected={(version) => {
                    props.updateSelectedSoftwareAppVersion(swa, version);
                  }}
                />
              )}
              {swa.scope === 'common' && (
                <CommonSoftwareAppVersionDropdown
                  softwareAppId={swa.idSoftwareApp!.toString()}
                  initiallySelectedVersion={initiallySelected}
                  selectedVersion={selectedVersion}
                  onSelected={(version) => {
                    props.updateSelectedSoftwareAppVersion(swa, version);
                  }}
                />
              )}
            </>
          );
        },
        width: '40%'
      },
      {
        title: 'Actions',
        key: 'actions',
        fixed: 'right',
        align: 'center',
        render: (swa: ScopedSoftwareApp) => {
          let currentSelection: SoftwareAppSelection | undefined;

          if (Object.keys(props.selectionMap).includes(getSelectionMapKey(swa))) {
            const s = props.selectionMap[getSelectionMapKey(swa)];
            currentSelection = { app: swa, version: s.version };
          }

          return (
            <Space>
              {swa.scope === 'project' && props.project && (
                <ProjectSoftwareAppsActionMenu currentSelection={currentSelection} swa={swa} project={props.project} />
              )}
              {swa.scope === 'common' && <CommonSoftwareAppsActionMenu currentSelection={currentSelection} swa={swa} project={props.project} />}
            </Space>
          );
        },
        width: '10%'
      }
    ];

    const topOffset = props.hasTopOffset ? 200 : 58;
    const hasSegmented = props.tabOptions.length > 2;
    const segmentedHeight = hasSegmented ? topOffset : 0;

    return (
      <div ref={setTableRef}>
        {hasSegmented ? (
          <Styled.SegmentedWrapper hasTopOffset={props.hasTopOffset}>
            <Segmented value={props.activeTabKey} options={props.tabOptions} onChange={props.onActiveTabChange} />
          </Styled.SegmentedWrapper>
        ) : null}
        <AppsTable
          rowSelection={
            props.hideCheckboxes
              ? undefined
              : {
                  type: 'checkbox',
                  ...props.rowSelection(),
                  columnWidth: 48
                }
          }
          sticky={{
            offsetHeader: segmentedHeight
          }}
          columns={columns as any}
          scroll={{ x: true }}
          rowKey={(record: ScopedSoftwareApp) => getSelectionMapKey(record)}
          rowClassName={scopedAppHash}
          dataSource={props.swApps.sort((a, b) => Comparator.lexicographicalComparison(a.name, b.name))}
          pagination={{
            current: tablePageNumber,
            onChange: (p) => {
              setTablePage(p.toString());
            },
            showSizeChanger: true,
            defaultPageSize: pageSizeNumber,
            pageSizeOptions: ['10', '20', '50'],
            onShowSizeChange: (c, s) => {
              setPageSize(s.toString());
            }
          }}
        />
      </div>
    );
  }
);
