import { Alert, Row, Col, Space, Typography } from 'antd';
import { useState, useRef, useEffect, useMemo } from 'react';
import { ProjectSoftwareAppsList } from './ProjectSoftwareAppsList';
import { AddProjectSoftwareApp } from './AddProjectSoftwareApp';
import { SoftwareAppSelection } from '../../../domain';
import { useBundleConfigurationVersion } from '../../bundles/hooks/useBundleConfigurationVersion';
import { useBundles } from '../../bundles/hooks/useBundles';
import { useDeleteBundleConfiguration } from '../../bundles/hooks/useDeleteBundleConfiguration';
import { useDeleteBundleConfigurationVersion } from '../../bundles/hooks/useDeleteBundleConfigurationVersion';
import { CreateDeploymentPlan } from '../../deployments/components/CreateDeploymentPlan';
import { useProject } from '../../projects/hooks/useProject';
import { usePermissions } from '../../session/hooks/usePermissions';
import { ExpandableMenu } from '../../shared/components/ExpandableMenu';
import { ProjectSoftwareAppsCompareList } from './ProjectSoftwareAppsCompareList';
import { AddBundleConfiguration } from '../../bundles/components/AddBundleConfiguration';
import { AddBundleConfigurationVersion } from '../../bundles/components/AddBundleConfigurationVersion';
import { BundleConfigurationVersionDropdown } from '../../bundles/components/BundleConfigVersionDropdown';
import { ReportDownloadButton } from '../../bundles/components/BundleReportDownloadButton';
import { ComparisonViewSwitch } from '../../bundles/components/ComparisonViewSwitch';
import { RevertButton } from '../../bundles/components/RevertButton';
import { ShowBundleItemsOnlySwitch } from '../../bundles/components/ShowBundleItemsOnlySwitch';
import { ConfirmationButton } from '../../shared/components/ConfirmationButton';
import { scopeToCommon, scopeToProject } from '../hooks/scopedSoftwareApp';
import { useSearchParameter } from '../../navigation/hooks/useSearchParameter';
import { formatDateTime } from '../../shared/components/formatDate';
import { MarkdownPreview } from '../../shared/components/MarkdownPreview';
import { BundleConfigurationSelection } from '../../../domain/bundleConfigurationSelection';
import { BundleRelease } from '../../../api/engineering/domain/types';
import { UpdateBundleConfiguration } from '../../bundles/components/EditBundleConfiguration';
import ProjectContentWrapper from '../../../contexts/projects/components/ProjectContentWrapper';
import { useParams } from 'react-router-dom';
import { useBundleConfigurationVersions, useBundleConfigurations } from '../../../contexts/bundles';

import styled from 'styled-components';
import { LINKS } from '../../../constants/links';

const { Text, Title } = Typography;

const initialBundleSelection: BundleConfigurationSelection = {
  bundleId: '',
  projectId: '',
  bundleConfigurationId: '',
  bundleConfigurationVersionId: '',
  bundleConfigurationName: '',
  bundleConfigurationVersionName: ''
};

const OuterSpace = styled(Space)`
  float: right !important;
`;

const InnerSpace = styled(Space)`
  float: right !important;
  margin-bottom: 15px !important;
`;

export const ProjectSoftwareAppsBundleView = () => {
  const params = useParams();
  const [selectedBundleConfigPrimary, setSelectedBundleConfigPrimary] = useState<BundleConfigurationSelection>(initialBundleSelection);
  const [selectedBundleConfigSecondary, setSelectedBundleConfigSecondary] = useState<BundleConfigurationSelection>(initialBundleSelection);
  const projectId = useMemo(() => {
    setSelectedBundleConfigPrimary({ ...initialBundleSelection, bundleConfigurationId: String(params?.configurationId) });
    setSelectedBundleConfigSecondary({ ...initialBundleSelection, bundleConfigurationId: String(params?.configurationId) });
    return params?.projectId || '';
  }, [params?.projectId, setSelectedBundleConfigPrimary, setSelectedBundleConfigSecondary, params?.configurationId]);
  const permissions = usePermissions({ projectId: projectId.toString() });
  const emptyArray = useRef([]);
  const [selectedApps, setSelectedApps] = useState<SoftwareAppSelection[] | undefined>();
  const [initialSelection, setInitialSelection] = useState([] as SoftwareAppSelection[]);
  const [comparisonViewSearchParam, setComparisonViewSearchParam] = useSearchParameter('mode');
  const [isDirty, setIsDirty] = useState(false);
  const [showSearchParameter] = useSearchParameter('show');
  const [projectType] = useSearchParameter('type');
  const isNoSelectedApps = (selectedApps?.length || 0) < 1;
  const comparisonView = comparisonViewSearchParam === 'compare';
  const setComparisonView = (enabled: boolean) => setComparisonViewSearchParam(enabled ? 'compare' : 'control');

  const project = useProject(projectId);
  const bundles = useBundles(projectId);

  const usedBundle = bundles.data?.find((bundle) => bundle.name.includes('Software Applications'));
  const bundleId = usedBundle?.idBundle?.toString() || '';

  const deleteBundleConfigVersion = useDeleteBundleConfigurationVersion();
  const deleteBundleConfig = useDeleteBundleConfiguration();

  const bundleConfigurations = useBundleConfigurations(projectId, bundleId);
  const configName = (bundleConfigurations.data || []).find((config) => config.idBundleConfiguration === Number(params.configurationId))?.name;

  const showBundleItemsOnly = !showSearchParameter || showSearchParameter === 'bundle';

  // if a project is an rpl and has a bundle config, no further can be added
  const isRpl = !!project.data?.projectType.isReferenceProject;
  const addBundleConfigDisabledDueToRpl = isRpl && !!selectedBundleConfigPrimary.bundleConfigurationId;
  const bundleConfigVersions = useBundleConfigurationVersions(projectId, bundleId, selectedBundleConfigPrimary.bundleConfigurationId);

  const bundleReleaseA = useBundleConfigurationVersion(
    projectId,
    bundleId,
    selectedBundleConfigPrimary.bundleConfigurationId,
    selectedBundleConfigPrimary.bundleConfigurationVersionId
  );

  const bundleReleaseB = useBundleConfigurationVersion(
    projectId,
    bundleId,
    selectedBundleConfigSecondary.bundleConfigurationId,
    selectedBundleConfigSecondary.bundleConfigurationVersionId
  );
  const hasBundles = !bundleConfigVersions.isLoading && bundleConfigVersions.data?.length && bundleConfigVersions.data.length > 0;

  const breadcrumbItems = [
    { title: project.data?.name, url: `/projects?active=${projectId}&type=${projectType}` },
    { title: 'Applications', url: `/projects/${projectId}/apps?active=${projectId}&type=${projectType}` },
    { title: configName, onClick: comparisonView ? () => setComparisonView(false) : undefined },
    ...(comparisonView
      ? [
          {
            title: 'Compare releases'
          }
        ]
      : [])
  ];

  const extractSelectionFromBundleRelease = useMemo(
    () => (data: BundleRelease) => {
      if (!data) {
        return emptyArray.current;
      }
      const matchedProjectApps = (data.projectSoftwareApps || []).map(scopeToProject).map((et) => {
        return { app: et, version: et.latestVersion!, target: et.latestVersion!.targets[0] };
      });

      const matchedCommonApps = (data.softwareApps || []).map(scopeToCommon).map((et) => {
        return { app: et, version: et.latestVersion!, target: et.latestVersion!.targets[0] };
      });

      return [...matchedProjectApps, ...matchedCommonApps];
    },
    []
  );

  const extractedAppsLeft = useMemo(() => {
    if (!bundleReleaseB.isSuccess) {
      return undefined;
    }
    return extractSelectionFromBundleRelease(bundleReleaseB.data);
  }, [bundleReleaseB.isSuccess, bundleReleaseB.data, extractSelectionFromBundleRelease]);

  const handleBundleConfigVersionChange = (val: BundleConfigurationSelection) => {
    setSelectedBundleConfigPrimary(val);
  };

  const handleSecondaryBundleConfigVersionChange = (val: BundleConfigurationSelection) => {
    setSelectedBundleConfigSecondary(val);
  };

  useEffect(() => {
    if (bundleReleaseA.data) {
      setInitialSelection(extractSelectionFromBundleRelease(bundleReleaseA.data));
      setSelectedApps(extractSelectionFromBundleRelease(bundleReleaseA.data));
    } else {
      setInitialSelection([]);
      setSelectedApps(undefined);
    }
  }, [bundleReleaseA.data, setInitialSelection, setSelectedApps, extractSelectionFromBundleRelease]);

  const configDropdownExtensions =
    (!comparisonView && [
      <ExpandableMenu id={`bundle-config-actions-${projectId}-${bundleId}`}>
        {permissions.engineeringSvc$addProjectBundleConfiguration && (
          <AddBundleConfiguration
            type="default"
            disabled={addBundleConfigDisabledDueToRpl}
            projectId={projectId.toString()}
            bundleId={bundleId}
            onAddBundleConfiguration={(bc) => {
              setSelectedBundleConfigPrimary({
                ...selectedBundleConfigPrimary,
                bundleConfigurationId: bc.idBundleConfiguration?.toString() || '',
                bundleConfigurationVersionId: '-1'
              });
            }}
          />
        )}
        {permissions.engineeringSvc$updateProjectBundleConfiguration && (
          <UpdateBundleConfiguration
            projectId={projectId.toString()}
            bundleId={bundleId}
            configId={selectedBundleConfigPrimary.bundleConfigurationId}
            onEditBundleConfigurationName={(bc) => {
              setSelectedBundleConfigPrimary({
                ...selectedBundleConfigPrimary,
                bundleConfigurationId: bc.idBundleConfiguration?.toString() || '',
                bundleConfigurationVersionId: bc.latestBundleReleaseId?.toString() || '-1'
              });
            }}
          />
        )}
        {permissions.engineeringSvc$deleteProjectBundleConfiguration && (
          <ConfirmationButton
            caption="Delete Bundle Configuration"
            danger
            description="All deployment plans associated with this configuration will also be deleted."
            onOk={() => {
              deleteBundleConfig.mutate([projectId.toString(), bundleId, selectedBundleConfigPrimary.bundleConfigurationId]);
            }}
          />
        )}
      </ExpandableMenu>
    ]) ||
    [];

  const configVersionDropdownExtensions =
    (!comparisonView && [
      <RevertButton disabled={!isDirty} onClick={() => setSelectedApps(initialSelection)} />,
      <ExpandableMenu id={`bundle-config-version-actions-${projectId}-${bundleId}-${selectedBundleConfigPrimary.bundleConfigurationId}`}>
        {permissions.engineeringSvc$addProjectBundleConfigurationRelease && (
          <AddBundleConfigurationVersion
            projectId={projectId.toString()}
            bundleId={bundleId}
            disabled={isNoSelectedApps}
            bundleConfigurationId={selectedBundleConfigPrimary.bundleConfigurationId}
            selectedEngineeringToolVersionIds={emptyArray.current}
            selectedSoftwareAppVersionIds={(selectedApps ?? [])
              .filter((sa) => sa.app.scope === 'common')
              .map((sa) => sa.version.idSoftwareAppVersion || -1)
              .filter((sai) => sai > -1)}
            selectedProjectSoftwareAppVersionIds={(selectedApps ?? [])
              .filter((sa) => sa.app.scope === 'project')
              .map((sa) => sa.version.idSoftwareAppVersion || -1)
              .filter((sai) => sai > -1)}
            onAddBundleConfigurationVersion={(bcv) => {
              setSelectedBundleConfigPrimary({
                ...selectedBundleConfigPrimary,
                bundleConfigurationVersionId: bcv.idBundleRelease?.toString() || ''
              });
            }}
          />
        )}
        {permissions.engineeringSvc$deleteProjectBundleConfigurationRelease && (
          <ConfirmationButton
            caption="Delete Version"
            danger
            onOk={() =>
              deleteBundleConfigVersion.mutate([
                projectId.toString(),
                bundleId,
                selectedBundleConfigPrimary.bundleConfigurationId,
                selectedBundleConfigPrimary.bundleConfigurationVersionId
              ])
            }
          />
        )}
      </ExpandableMenu>
    ]) ||
    [];

  const bundleActions = (
    <>
      <ExpandableMenu id={`bundle-config-version-extra-actions-${projectId}-${bundleId}-${selectedBundleConfigPrimary.bundleConfigurationId}`}>
        {hasBundles && <ComparisonViewSwitch state={comparisonView} onChange={() => setComparisonView(!comparisonView)} />}
        {!comparisonView && permissions.engineeringSvc$addProjectSoftwareApp && <AddProjectSoftwareApp projectId={projectId.toString()} />}
        {!comparisonView &&
          permissions.engineeringSvc$getProjectBundleConfigurationReleaseReport &&
          project.data &&
          usedBundle &&
          selectedBundleConfigPrimary &&
          hasBundles && <ReportDownloadButton project={project.data} bundle={usedBundle} bundleConfig={selectedBundleConfigPrimary} isDirty={isDirty} />}
        {!comparisonView && permissions.deploymentSvc$addDeploymentPlan && hasBundles && (
          <CreateDeploymentPlan
            projectId={parseInt(projectId)}
            bundleId={parseInt(bundleId)}
            disabled={isDirty || !selectedApps}
            bundleConfigId={parseInt(selectedBundleConfigPrimary.bundleConfigurationId)}
            bundleConfigVersionId={parseInt(selectedBundleConfigPrimary.bundleConfigurationVersionId)}
            selection={selectedApps ?? []}
          />
        )}
      </ExpandableMenu>
    </>
  );

  const aIsEmpty = !selectedBundleConfigPrimary.bundleConfigurationVersionId && selectedBundleConfigPrimary.bundleId;
  const bIsEmpty = !selectedBundleConfigSecondary.bundleConfigurationVersionId && selectedBundleConfigSecondary.bundleId;
  const aLoading = (bundleReleaseA.isLoading || bundleReleaseA.data === undefined) && !aIsEmpty;
  const bLoading = comparisonView && (bundleReleaseB.isLoading || bundleReleaseB.data === undefined) && !bIsEmpty;
  const loading = aLoading || bLoading;

  useEffect(() => {
    if (selectedApps === undefined && aIsEmpty) {
      setSelectedApps([]);
    }
  }, [aIsEmpty, selectedApps, setSelectedApps]);

  // TODO: extract validation for ids and use in hooks as well
  if (parseInt(projectId, 10) < 0) {
    return <Alert message="Invalid Project Id" type="error" />;
  }

  return (
    <ProjectContentWrapper isLoading={project.isLoading} breadcrumbItems={breadcrumbItems}>
      <Row justify="start">
        <Col flex="50%">
          {!!configName && (
            <Title level={4} style={{ margin: 0, marginBottom: 4 }}>
              {configName}
            </Title>
          )}
          {!comparisonView && bundleReleaseA.data && (
            <>
              <Text type="secondary">Release Notes:</Text>
              <MarkdownPreview content={bundleReleaseA.data.releaseNotes || ''} title="Release note" />
              <br />
              <Text type="secondary">
                {bundleReleaseA.data.createdAt ? formatDateTime(new Date(bundleReleaseA.data.createdAt)) : ''}
                {bundleReleaseA.data.createdBy ? ` by ${bundleReleaseA.data.createdBy}` : ''}
              </Text>
            </>
          )}
        </Col>
        <Col flex="auto">
          <OuterSpace direction="vertical" size={0}>
            {!bundleConfigVersions.isLoading &&
              !bundleConfigVersions.data?.length &&
              permissions.engineeringSvc$addProjectBundleConfigurationRelease &&
              !comparisonView && (
                <InnerSpace>
                  <AddBundleConfigurationVersion
                    type="button"
                    projectId={projectId.toString()}
                    bundleId={bundleId}
                    disabled={isNoSelectedApps}
                    bundleConfigurationId={selectedBundleConfigPrimary.bundleConfigurationId}
                    selectedEngineeringToolVersionIds={emptyArray.current}
                    selectedSoftwareAppVersionIds={(selectedApps ?? [])
                      .filter((sa) => sa.app.scope === 'common')
                      .map((sa) => sa.version.idSoftwareAppVersion || -1)
                      .filter((sai) => sai > -1)}
                    selectedProjectSoftwareAppVersionIds={(selectedApps ?? [])
                      .filter((sa) => sa.app.scope === 'project')
                      .map((sa) => sa.version.idSoftwareAppVersion || -1)
                      .filter((sai) => sai > -1)}
                    onAddBundleConfigurationVersion={(bcv) => {
                      setSelectedBundleConfigPrimary({
                        ...selectedBundleConfigPrimary,
                        bundleConfigurationVersionId: bcv.idBundleRelease?.toString() || ''
                      });
                    }}
                  />
                </InnerSpace>
              )}
            <OuterSpace>
              <BundleConfigurationVersionDropdown
                disableDropdown={comparisonView}
                hideConfiguration={!comparisonView}
                hideEmptyVesions={!comparisonView}
                projectId={projectId.toString()}
                bundleId={bundleId}
                selected={selectedBundleConfigPrimary}
                onSelect={handleBundleConfigVersionChange}
                isDirty={isDirty}
                configurationDropdownExtensions={configDropdownExtensions}
                configurationVersionDropdownExtensions={configVersionDropdownExtensions}
                id="l"
              />
              {comparisonView && (
                <BundleConfigurationVersionDropdown
                  disableLabel
                  projectId={projectId.toString()}
                  bundleId={bundleId}
                  selected={selectedBundleConfigSecondary}
                  onSelect={handleSecondaryBundleConfigVersionChange}
                  id="r"
                />
              )}
            </OuterSpace>
            <OuterSpace>
              {!comparisonView && selectedBundleConfigPrimary && !loading && (
                <ShowBundleItemsOnlySwitch itemName="Apps" showAll={!hasBundles} extension={[bundleActions]} />
              )}
              {comparisonView && bundleActions}
            </OuterSpace>
          </OuterSpace>
        </Col>
      </Row>
      <Row style={{ marginTop: 24 }}>
        {!bundleConfigVersions.isLoading && !bundleConfigVersions.data?.length && (
          <Alert
            style={{ marginBottom: '20px', width: '100%', zIndex: 5 }}
            message={
              <Typography.Text>
                <Typography.Text strong>Create your first release</Typography.Text>. Please select the software needed for the release before creating it.{' '}
                <Typography.Link target="_blank" href={LINKS.LINK_WIKI_PACTS_CREATE_RELEASES}>
                  See How to create releases
                </Typography.Link>
                .
              </Typography.Text>
            }
            type="warning"
            showIcon
          />
        )}
        <Col span={24}>
          {!comparisonView && project.data && (
            <ProjectSoftwareAppsList
              onDirty={setIsDirty}
              project={project.data}
              selected={selectedApps}
              initiallySelected={initialSelection}
              onSelect={(selected) => setSelectedApps([...selected])}
              showBundleItemsOnly={showBundleItemsOnly}
              loading={loading}
            />
          )}
          {comparisonView && (
            <ProjectSoftwareAppsCompareList
              project={project.data}
              selectedTitleLeft={`${selectedBundleConfigSecondary.bundleConfigurationName}: ${selectedBundleConfigSecondary.bundleConfigurationVersionName}`}
              selectedTitleRight={`${selectedBundleConfigPrimary.bundleConfigurationName}: ${selectedBundleConfigPrimary.bundleConfigurationVersionName}`}
              projectId={projectId.toString()}
              selectedAppsRight={selectedApps}
              selectedAppsLeft={extractedAppsLeft}
              loading={loading}
            />
          )}
        </Col>
      </Row>
    </ProjectContentWrapper>
  );
};
