import { Result } from 'antd';
import { Comparator } from '../../../domain';
import { useProjects } from '../hooks';
import { SelectedProjectCard } from './SelectedProjectCard';
import { useLocalStorageState } from '../../../contexts/shared/hooks/useLocalStorageState';
import { useSearchParameter } from '../../../contexts/navigation/hooks/useSearchParameter';

import { projectCardHeight } from './ProjectsLayout';

export const ProjectsPortal = () => {
  const projects = useProjects();
  const [pinnedProjects] = useLocalStorageState<string[]>('pinned-projects');
  const [type] = useSearchParameter('type');
  const [active] = useSearchParameter('active');
  const typeFilteredProjects = (
    projects.data?.filter((p) => {
      return type ? p.projectType.idProjectType.toString() === type : true;
    }) || []
  )
    .sort((a, b) => Comparator.lexicographicalComparison(a.name, b.name))
    .sort((a, b) => {
      const aN = pinnedProjects?.includes(a.idProject.toString()) ? 1 : 0;
      const bN = pinnedProjects?.includes(b.idProject.toString()) ? 1 : 0;
      return bN - aN;
    });

  const firstUnarchivedProject = typeFilteredProjects.find((p) => !p.isArchived);

  const activeProject = typeFilteredProjects?.find((p) => p.idProject.toString() === active) ?? firstUnarchivedProject;

  return activeProject ? (
    <SelectedProjectCard selectedProject={activeProject} minHeight={projectCardHeight} />
  ) : (
    <center>
      <Result>Select Project from list</Result>
    </center>
  );
};
