import { useState } from 'react';
import { Form, Input, Select, Tooltip } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { BundleConfiguration } from '@pacts/engineeringservice-api';
import { usePermissions } from '../../session/hooks/usePermissions';
import { ExpandableMenuItem } from '../../shared/components/ExpandableMenuItem';
import { useUpdateBundleConfiguration } from '../hooks/useUpdateBundleConfiguration';
import { useBundleConfiguration, useBundleConfigurationVersions } from '../hooks';
import { DescriptionValidator, NameValidator } from '../../../domain';
import { ShiftedDrawer } from '../../shared/components/ShiftedDrawer';
import { DescriptionTextAreaInput } from '../../shared/components/DescriptionTextAreaInput';

export type UpdateBundleConfigurationProps = {
  projectId: string;
  bundleId: string;
  configId: string;
  title?: string;
  onEditBundleConfigurationName?: (bundleConfiguration: BundleConfiguration) => any;
};

export const UpdateBundleConfiguration = (props: UpdateBundleConfigurationProps) => {
  const permissions = usePermissions({ projectId: props.projectId });
  const bundleConfig = useBundleConfiguration(props.projectId, props.bundleId, props.configId);
  const bundleConfigVersions = useBundleConfigurationVersions(props.projectId, props.bundleId, props.configId);
  const [form] = Form.useForm();
  const title = props.title || 'Edit Bundle Configuration';
  const initialFieldValue = {
    name: bundleConfig.data?.name,
    latestBundleReleaseId: bundleConfigVersions.data?.find((element) => element.idBundleRelease === bundleConfig.data?.latestBundleReleaseId)?.idBundleRelease,
    description: bundleConfig.data?.description
  };
  const [modalVisible, setModalVisible] = useState(false);
  const createBundleConfiguration = useUpdateBundleConfiguration();
  const onSubmit = () => {
    form.submit();
  };

  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 }
  };
  return (
    <>
      {permissions.engineeringSvc$updateProjectBundleConfiguration && (
        <Tooltip title={title}>
          <ExpandableMenuItem
            icon={<EditOutlined />}
            loading={createBundleConfiguration.isLoading}
            onClick={() => {
              setModalVisible(true);
              // Reset with delay, so form is rendered before reset
              setTimeout(() => {
                form.resetFields();
              }, 10);
            }}
          >
            {title}
          </ExpandableMenuItem>
        </Tooltip>
      )}

      <ShiftedDrawer
        destroyOnClose
        width={640}
        maskClosable={false}
        title="Edit Bundle Configuration"
        loading={createBundleConfiguration.isLoading}
        open={modalVisible}
        isFormDrawer
        onOk={onSubmit}
        onCancel={() => {
          setModalVisible(false);
        }}
      >
        <Form
          {...layout}
          name="basic"
          form={form}
          initialValues={initialFieldValue}
          onFinish={(value) => {
            const copy = { ...initialFieldValue, ...value, idBundleConfiguration: parseInt(props.configId, 10) } as BundleConfiguration;
            createBundleConfiguration
              .mutateAsync([props.projectId, props.bundleId, copy])
              .then((bc: BundleConfiguration) => {
                if (props.onEditBundleConfigurationName && bc) {
                  props.onEditBundleConfigurationName(bc);
                }
                setModalVisible(false);
              })
              .catch(() => {
                /* */
              });
          }}
        >
          <Form.Item label="Name" name="name" rules={[NameValidator.rule()]}>
            <Input />
          </Form.Item>
          <Form.Item label="Description" name="description" rules={[DescriptionValidator.rule()]}>
            <DescriptionTextAreaInput />
          </Form.Item>
          <Form.Item label="Latest release" name="latestBundleReleaseId">
            <Select className={`latest-bundle-release-selector-${props.projectId}-${props.bundleId}-${props.configId}`}>
              {bundleConfigVersions.data?.map((version) => {
                return (
                  <Select.Option key={version.idBundleRelease} value={version.idBundleRelease}>
                    {version.version}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Form>
      </ShiftedDrawer>
    </>
  );
};
