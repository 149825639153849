import { Row, Col, Space, Divider, Typography, Tag, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

import { ProjectActions } from './ProjectActions';
import { useProjectMembers } from '../hooks';
import { useCurrentUser } from '../../../contexts/session';
import { useTheme, THEMES } from '../../theme/ThemeProvider';
import ProjectContentWrapper from './ProjectContentWrapper';
import { ProjectAccessRequests } from './ProjectAccessRequests';
import { ProjectSectionTitle } from './ProjectSectionTitle';
import { GrafanaDashboardButton } from './GrafanaDashboardButton';
import { ProjectDescription } from './ProjectDescription';

import type { Project } from '../../../api';
import { useMemo } from 'react';
import styled from 'styled-components';

const ReleaseManagerText = styled(Typography.Text)`
  .ant-typography-copy {
    color: ${({ theme }) => theme.colorText};
  }
`;

const StatusContainer = styled.div<{ isLightTheme: boolean }>`
  margin: 12px -24px 24px;
  padding: 12px 24px;
  display: flex;
  align-items: center;
  background-color: ${({ theme, isLightTheme }) => (isLightTheme ? theme.primitivesLight.neutral[2] : theme.primitivesDark.neutral[10])};
  border-top: 2px solid ${({ theme, isLightTheme }) => (isLightTheme ? theme.primitivesLight.neutral[6] : theme.primitivesDark.neutral[11])};
  border-bottom: 2px solid ${({ theme, isLightTheme }) => (isLightTheme ? theme.primitivesLight.neutral[6] : theme.primitivesDark.neutral[11])};
`;

const StyledDivider = styled(Divider)<{ isLightTheme: boolean }>`
  &.ant-divider-vertical {
    height: 1.5em;
    border-inline-start: 1px solid ${({ theme, isLightTheme }) => (isLightTheme ? theme.primitivesLight.neutral[6] : theme.primitivesDark.neutral[11])};
  }
`;

const StyledTag = styled(Tag)`
  margin: 0 !important;
`;

const CaptionContainer = styled.div`
  max-width: 100%;
  margin-bottom: 30px;
`;

export const SelectedProjectCard = (props: { selectedProject: Project; minHeight: string }) => {
  const members = useProjectMembers(props.selectedProject.idProject.toString());
  const [theme] = useTheme();

  const isLightTheme = theme === THEMES.LIGHT;

  const releaseManagers = useMemo(
    () => (members.data || []).filter((member) => member.roles.some((role) => role.name.toLowerCase().includes('release manager'))),
    [members.data]
  );

  const user = useCurrentUser();
  const myself = members.data?.find((m) => m.member.idMember === user.id);
  const breadcrumbItems = [{ title: props.selectedProject.name }];

  return (
    <ProjectContentWrapper breadcrumbItems={breadcrumbItems}>
      <Row>
        <Col span={24}>
          <Space direction="vertical" style={{ width: '100%' }}>
            <Row gutter={[8, 8]}>
              <Col flex="auto">
                <Typography.Title level={4} style={{ marginBottom: 0 }}>
                  {props.selectedProject.name}
                </Typography.Title>
              </Col>
              <Col flex="32px">
                <ProjectActions project={props.selectedProject} />
              </Col>
            </Row>

            <StatusContainer isLightTheme={isLightTheme}>
              <Space split={<StyledDivider isLightTheme={isLightTheme} type="vertical" />} wrap>
                {props.selectedProject.isArchived ? <StyledTag>Archived</StyledTag> : null}

                <Typography.Text type="secondary">{`Status: ${props.selectedProject.status}`}</Typography.Text>

                <Typography.Text type="secondary">{`Phase: ${props.selectedProject.phase.name}`}</Typography.Text>

                <Typography.Text type="secondary">{`Solution Type: ${props.selectedProject.projectSolutionType.name}`}</Typography.Text>

                {props.selectedProject.referenceProjectRelease && (
                  // eslint-disable-next-line max-len
                  <Typography.Text type="secondary">{`RPL: ${props.selectedProject.referenceProjectRelease.projectName} / ${props.selectedProject.referenceProjectRelease.releaseVersion}`}</Typography.Text>
                )}
              </Space>
            </StatusContainer>
          </Space>
        </Col>
      </Row>

      <ProjectAccessRequests project={props.selectedProject} />

      <Row justify="space-between" wrap={false}>
        <Col>
          <CaptionContainer>
            <ProjectSectionTitle level={5}>Description</ProjectSectionTitle>
            <ProjectDescription project={props.selectedProject} />
          </CaptionContainer>

          {/* Commenting out for implementation after we have APIs: 
          https://code.siemens-energy.com/emts_engineering_platform/digital-services/pacts/engineering-platform-service/-/issues/2545 */}
          {/* <CaptionContainer>
            <ProjectSectionTitle level={5}>Latest updates</ProjectSectionTitle>
            <Typography.Paragraph style={{ marginBottom: '5px' }}>
              Latest release: <Typography.Text>asd</Typography.Text>
            </Typography.Paragraph>
            <Typography.Paragraph>
              Latest deployment: <Typography.Text>asd</Typography.Text>
            </Typography.Paragraph>
          </CaptionContainer> */}

          <CaptionContainer>
            <ProjectSectionTitle level={5}>Analytics</ProjectSectionTitle>

            <GrafanaDashboardButton target={props.selectedProject} />

            {/* Same comment as above */}
            {/* <Row style={{ marginTop: '15px' }}>
              <Card bordered={false} style={{ marginRight: '15px', width: '200px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography.Text>Project releases</Typography.Text>
                <Typography.Title style={{ textAlign: 'center', margin: '0' }}>15</Typography.Title>
              </Card>
              <Card bordered={false} style={{ marginRight: '15px', width: '200px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography.Text>Software applications</Typography.Text>
                <Typography.Title style={{ textAlign: 'center', margin: '0' }}>162</Typography.Title>
              </Card>
              <Card bordered={false} style={{ width: '200px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography.Text>Project members</Typography.Text>
                <Typography.Title style={{ textAlign: 'center', margin: '0' }}>20</Typography.Title>
              </Card>
            </Row> */}
          </CaptionContainer>
        </Col>
        <Col>
          {!!myself?.roles && (
            <CaptionContainer>
              <ProjectSectionTitle level={5} smallWidth>
                My project role
              </ProjectSectionTitle>
              {myself?.roles.map((r) => (
                <li style={{ marginTop: 16 }}>
                  <Typography.Text>
                    {r.name}
                    <Tooltip title={r.description} trigger={['click', 'hover']}>
                      <InfoCircleOutlined style={{ marginLeft: '5px' }} />
                    </Tooltip>
                  </Typography.Text>
                </li>
              ))}
            </CaptionContainer>
          )}
          <CaptionContainer>
            <ProjectSectionTitle level={5} smallWidth>
              Release managers
            </ProjectSectionTitle>
            {releaseManagers.map((manager) => (
              <li style={{ marginTop: 16 }}>
                <ReleaseManagerText copyable={{ text: manager.member.email ?? '' }}>{manager.member.name}</ReleaseManagerText>
              </li>
            ))}
          </CaptionContainer>
        </Col>
      </Row>
    </ProjectContentWrapper>
  );
};
