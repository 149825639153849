import { Button, Tooltip } from 'antd';
import { useRef } from 'react';
import styled from 'styled-components';

import type { ComponentToken } from 'antd/es/button/style';

type ExtendedButtonToken = ComponentToken & {
  colorBgContainerDisabled: string;
  colorTextDisabled: string;
};

type ExpandableMenuItemProps = {
  icon?: React.ReactNode;
  children: string | React.ReactNode;
  danger?: boolean;
  loading?: boolean;
  disabled?: boolean;
  buttonMode?: boolean;
  tooltip?: string;
  onClick?: () => any;
};

const StyledButton = styled(Button)<{ buttonMode?: boolean; isDanger?: boolean }>`
  color: inherit;
  transition: none;
  ${({ buttonMode }) => (buttonMode ? '' : 'width: 100%;')}

  &:hover {
    ${({ disabled, theme }) => (!!disabled ? `background-color: ${(theme.Button as ExtendedButtonToken).colorBgContainerDisabled};` : 'background-color: transparent !important;')}

    color: ${({ isDanger, theme, disabled }) => {
      if (isDanger && !disabled) {
        return `${theme.dropdownDangerTextColor} !important`;
      } else if (disabled) {
        return (theme.Button as ExtendedButtonToken)?.colorTextDisabled;
      }

      return 'inherit';
    }};

    ${({ isDanger, theme, disabled }) => {
      if (isDanger && !disabled) {
        return `background-color: ${theme.colorErrorHover} !important;`;
      }
    }}
  }
`;

const StyledButtonText = styled.span`
  flex: auto;
  text-align: left;
`;

export const ExpandableMenuItem = (props: ExpandableMenuItemProps) => {
  const disabled = props.disabled || props.loading;
  const ref = useRef(null);

  const button = (
    <Tooltip title={props.tooltip} trigger={['click', 'hover']}>
      <StyledButton
        buttonMode={props.buttonMode}
        type={props.buttonMode ? 'default' : 'text'}
        ref={ref}
        disabled={disabled}
        danger={props.buttonMode ? props.danger : undefined}
        isDanger={props.danger}
        icon={props.icon}
        loading={props.loading}
        onClick={props.onClick}
      >
        <StyledButtonText>{props.children}</StyledButtonText>
      </StyledButton>
    </Tooltip>
  );

  return button;
};
