import { useForm } from 'antd/lib/form/Form';
import { useSearchParameters } from '../../../contexts/navigation/hooks/useSearchParameters';
import { useCreateDeploymentPlan } from '../hooks/useCreateDeploymentPlan';
import { CreateDeploymentPlanForm, DeploymentPlanPrototype } from './DeploymentPlanForm';
import { useInAppNavigate } from '../../navigation/hooks/useInAppNavigate';
import { SoftwareComponent, SoftwareComponentDeployment } from '../../../api';
import { useParams } from 'react-router-dom';
import { useProject } from '../../../contexts/projects/hooks/useProject';
import { useDeploymentEnvironments } from '../hooks/useDeploymentEnvironments';
import { groupBy } from 'lodash';
import ProjectContentWrapper from '../../../contexts/projects/components/ProjectContentWrapper';
import { useSearchParameter } from '../../../contexts/navigation/hooks';
import { useBundleConfiguration } from '../../../contexts/bundles';

const CreateDeploymentPage = () => {
  const navigate = useInAppNavigate();
  const params = useParams();
  const projectId = parseInt(params.projectId ?? '0');
  const envs = useDeploymentEnvironments(projectId);
  const searchParams = useSearchParameters();
  const [projectType] = useSearchParameter('type');
  const [bundleConfigId] = useSearchParameter('bundleConfigId');
  const [bundleId] = useSearchParameter('bundleId');
  const bundleConfig = useBundleConfiguration(String(projectId), String(bundleId), String(bundleConfigId));
  const project = useProject(projectId.toString());
  const createDeploymentPlan = useCreateDeploymentPlan();
  const [form] = useForm();
  const breadcrumbItems = [
    { title: project.data?.name, url: `/projects?active=${projectId}&type=${projectType}` },
    { title: 'Applications' },
    {
      title: bundleConfig.data?.name,
      url: `/projects/${projectId}/apps/configurations/${bundleConfigId}?active=${projectId}&type=${projectType}`
    },
    { title: 'Create new deployment' }
  ];

  const handleFinish = async (ps: DeploymentPlanPrototype) => {
    const envId = ps.envId;
    const allDeployments = ps.deployments
      .map((d) => {
        return d.device.map((device) => {
          // TODO: infer scope and type
          console.warn('assuming scope and type for software component');
          const swComponent: SoftwareComponent = {
            id: d.selection.app.idSoftwareApp,
            versionId: Number(d.selection.versionId),
            name: d.selection.app.name,
            version: d.selection.version.version,
            scope: d.selection.app.scope,
            type: 'app'
          };
          const deployment: SoftwareComponentDeployment = {
            device,
            components: [swComponent]
          };
          return deployment;
        });
      })
      .flat();
    const deviceGroupedDeployments = groupBy(allDeployments, (d) => d.device.id);
    const deployments = Object.entries(deviceGroupedDeployments).map((k) => {
      const deployment: SoftwareComponentDeployment = {
        device: k[1][0].device,
        components: k[1].map((d) => d.components).flat()
      };
      return deployment;
    });
    createDeploymentPlan
      .mutateAsync([
        {
          name: ps.name,
          description: ps.description,
          deployments,
          release: {
            bundleId: +searchParams.bundleId,
            configId: +searchParams.bundleConfigId,
            releaseId: +searchParams.bundleConfigVersionId
          }
        },
        projectId,
        envId
      ])
      .then((res: any) => {
        if (res) {
          form.resetFields();
          navigate(`/projects/${projectId}/deployments/${envId}`);
        }
      })
      .catch(() => {
        /* */
      });
  };

  return (
    <ProjectContentWrapper title={`${project.data?.name || ''}: New deployment`} breadcrumbItems={breadcrumbItems}>
      <CreateDeploymentPlanForm
        envs={envs}
        bundleConfigVersionId={parseInt(searchParams.bundleConfigVersionId ?? '0', 10)}
        bundleConfigId={parseInt(searchParams.bundleConfigId ?? '0', 10)}
        bundleId={parseInt(searchParams.bundleId ?? '0', 10)}
        projectId={projectId}
        form={form}
        isCreateButtonLoading={createDeploymentPlan.isLoading}
        onFinish={handleFinish}
      />
    </ProjectContentWrapper>
  );
};

export default CreateDeploymentPage;
