import { ReactNode, useMemo, useState } from 'react';
import { List, Avatar, Skeleton, Segmented, Space, Typography } from 'antd';
import styled from 'styled-components';

import { Comparator } from '../../../domain/extensions/comparison';
import { useProjectRoles } from '../../administration/hooks/useProjectRoles';
import { useProjectMembers } from '../hooks/useProjectMembers';

import type { Project, ProjectMember } from '../../../api';

const StyledAvatar = styled(Avatar)`
  background-color: ${({ theme }) => theme.colorPrimary};
`;

const StyledText = styled(Typography.Text)<{ dim?: boolean }>`
  color: inherit;
  ${({ dim }) => (dim ? `opacity: 85%;` : '')}
`;

const StyledSegmented = styled(Segmented)`
  .ant-segmented-item {
    min-width: 150px;
  }
` as typeof Segmented;

const StyledSpace = styled(Space)`
  width: 100%;
`;

function sortProjectMembers(members: ProjectMember[]) {
  return members.sort((a, b) => Comparator.lexicographicalComparison(a.member.name.toLowerCase(), b.member.name.toLowerCase()));
}

type ProjectMemberListProps = {
  project: Project;
};

type SegmentedOption = {
  label: ReactNode;
  value: string;
};

const filterLabel = (option: string, numberOfMembers: number) => (
  <Space direction="vertical">
    <StyledText>{option}</StyledText>
    <StyledText dim>{numberOfMembers}</StyledText>
  </Space>
);

export const ProjectMemberList = (props: ProjectMemberListProps) => {
  const projectMembers = useProjectMembers(props.project.idProject.toString());
  const roles = useProjectRoles();

  const ALL_MEMBERS_FILTER = 'All Members';

  const [activeFilter, setActiveFilter] = useState<string>(ALL_MEMBERS_FILTER);

  const loading = projectMembers.isLoading;
  const success = projectMembers.isSuccess;

  const filters = useMemo(() => {
    const newFilters: SegmentedOption[] = [];

    if (success) {
      // 'All Members' is the first available filter, then add all the other roles as filters.
      newFilters.push({
        label: filterLabel(ALL_MEMBERS_FILTER, projectMembers.data.length),
        value: ALL_MEMBERS_FILTER
      });
      roles.data?.forEach((r) => {
        const numberOfMembers = projectMembers.data.filter((m) => m.roles.some((mr) => mr.idProjectRole === r.idProjectRole)).length;

        newFilters.push({
          label: filterLabel(r.name, numberOfMembers),
          value: r.name
        });
      });

      return newFilters;
    }

    return [];
  }, [roles.data, success, projectMembers.data]);

  const selectedMembers = useMemo(() => {
    if (!success) return [];

    if (activeFilter === ALL_MEMBERS_FILTER) {
      return sortProjectMembers(projectMembers.data);
    }

    const membersWithRole = projectMembers.data.filter((m) => (m.roles || []).some((role) => role.name === activeFilter));

    return sortProjectMembers(membersWithRole);
  }, [success, activeFilter, projectMembers.data]);

  return (
    <>
      {loading && <Skeleton active />}

      {success && (
        <StyledSpace direction="vertical" size="large">
          <StyledSegmented options={filters} onChange={(v: string) => setActiveFilter(v)} />

          <List
            dataSource={selectedMembers}
            grid={{ gutter: 16, column: 4 }}
            loading={projectMembers.isLoading}
            renderItem={(pm) => (
              <List.Item>
                <List.Item.Meta
                  avatar={<StyledAvatar>{pm.member.name[0]}</StyledAvatar>}
                  title={pm.member.name}
                  description={pm.roles
                    ?.map((r) => r.name)
                    .sort()
                    .join(', ')}
                />
              </List.Item>
            )}
          />
        </StyledSpace>
      )}
    </>
  );
};
