import { EllipsisOutlined } from '@ant-design/icons';
import { Button, Dropdown, MenuProps, Tooltip } from 'antd';
import { KeyboardEvent, isValidElement, useState } from 'react';
import styled from 'styled-components';

import type { TooltipProps } from 'antd';


const StyledSpan = styled.span`
  width: 100%;
`;

type ExpandableMenuProps = {
  id: string;
  icon?: React.ReactNode;
  children: React.ReactNode[];
  caption?: string | React.ReactNode;
  textType?: boolean;
  disabled?: boolean;
  style?: React.CSSProperties;
  enabledTooltip?: string;
  disabledTooltip?: string;
  tooltipPlacement?: TooltipProps['placement'];
  showEmpty?: boolean;
  loading?: boolean;
  size?: 'large' | 'middle' | 'small';
};

// keys stop propagation
// https://github.com/ant-design/ant-design/issues/34125
const ARROW_KEYS = ['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'];

const HOME_KEY = 'Home';
const END_KEY = 'End';

export const ExpandableMenu = (props: ExpandableMenuProps) => {
  const [openDropdown, setOpenDropdown] = useState(false);

  const icon = props.icon ?? <EllipsisOutlined />;
  const availableChildren = props.children.filter((c) => c);
  const hasAvailableChildren = availableChildren.length > 0;
  const disabled = props.disabled || !hasAvailableChildren;

  if (!hasAvailableChildren && !props.showEmpty) {
    return null;
  }

  const items: MenuProps['items'] = props.children.filter(Boolean).map((c, idx) => {
    const childIsValidReactElement = isValidElement(c);
    const childProps = childIsValidReactElement ? c.props : {};
    const childIsDisabled = childIsValidReactElement ? !!childProps.disabled : false;
    const childIsDanger = childIsValidReactElement ? !!childProps.danger : false;

    return {
      key: idx,
      danger: childIsDanger,
      disabled: childIsDisabled,
      label: (
        <StyledSpan
          onKeyDown={(e: KeyboardEvent<HTMLLIElement>): void => {
            const target = e.target as HTMLInputElement | HTMLTextAreaElement;
            if (ARROW_KEYS.indexOf(e.code) > -1) {
              e.stopPropagation();
            }
            /** Enable home-end key */
            if (e.key === HOME_KEY) {
              e.preventDefault();
              // Start at current position
              const currentPosition = target.selectionStart ?? 0;
              // Worst-case is start of input
              let targetPosition = 0;
              // From one before current position start searching for the next line break
              for (let i = currentPosition - 1; i >= 0; i--) {
                if (target.value[i] === '\n') {
                  // Set cursor to after line break
                  targetPosition = i + 1;
                  break;
                }
              }
              target.setSelectionRange(targetPosition, targetPosition);
            } else if (e.key === END_KEY) {
              e.preventDefault();
              // Start at current curser position
              const currentPosition = target.selectionStart ?? 0;
              // Worst-case is end of input
              let targetPosition = target.value.length;
              // From current position search for the next linebreak
              for (let i = currentPosition; i < target.value.length; i++) {
                if (target.value[i] === '\n') {
                  targetPosition = i;
                  break;
                }
              }
              target.setSelectionRange(targetPosition, targetPosition);
            }
            /** End enable home-end key */
          }}
        >
          {c}
        </StyledSpan>
      ),
      style: { padding: 0, marginTop: 2, marginBottom: 2 }
    };
  });

  if (disabled && props.disabledTooltip) {
    return (
      <Dropdown disabled={disabled} overlayStyle={{ margin: 0, padding: 0 }} arrow menu={{ items }} placement="bottomRight">
        <Tooltip title={props.disabledTooltip} placement="left" color="#262626" trigger={['hover']} getPopupContainer={(trigger) => trigger}>
          <Button
            id={props.id}
            loading={props.loading}
            disabled={disabled}
            style={props.style}
            type={props.textType ? 'text' : 'default'}
            icon={icon}
            tabIndex={0}
            size={props.size}
          >
            {props.caption}
          </Button>
        </Tooltip>
      </Dropdown>
    );
  }
  // TODO: refactor
  if (props.enabledTooltip) {
    return (
      <Tooltip
        title={openDropdown ? '' : props.enabledTooltip}
        placement={props.tooltipPlacement || 'left'}
        autoAdjustOverflow
        overlayStyle={{ maxWidth: '600px' }}
        overlayInnerStyle={{ maxWidth: '600px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
        trigger={['hover', 'click']}
        getPopupContainer={(trigger) => trigger}
      >
        <Dropdown 
          arrow 
          disabled={disabled} 
          menu={{ items }} 
          placement="bottomRight" 
          trigger={['click']} 
          overlayStyle={{ zIndex: 100 }}
          onOpenChange={(open) => setOpenDropdown(open)}
        >
          <Button
            id={props.id}
            loading={props.loading}
            disabled={disabled}
            style={props.style}
            type={props.textType ? 'text' : 'default'}
            icon={icon}
            tabIndex={0}
            size={props.size}
          >
            {props.caption}
          </Button>
        </Dropdown>
      </Tooltip>
    );
  }

  return (
    <Dropdown
      disabled={disabled}
      // overlayStyle={{ margin: 0, padding: 0 }}
      menu={{ items }}
      trigger={['click']}
      placement="bottomRight"
    >
      <Button
        onClick={(e) => e.stopPropagation()}
        id={props.id}
        loading={props.loading}
        disabled={disabled}
        style={props.style}
        type={props.textType ? 'text' : 'default'}
        icon={icon}
        size={props.size}
        tabIndex={0}
      >
        {props.caption}
      </Button>
    </Dropdown>
  );
};
