import { Col, Layout, Row, Skeleton } from 'antd';
import { extractProjectId, ProjectsMenu } from './ProjectsMenu';
import React, { useEffect, useState } from 'react';
import { useProjectSolutionTypes, useProjectTypes, useProjects } from '../hooks';
import { useLocalStorageState } from '../../../contexts/shared/hooks';
import { Comparator } from '../../../domain';
import { useSearchParameter } from '../../../contexts/navigation/hooks';
import styled from 'styled-components';

interface ProjectsLayoutProps {
  children: JSX.Element;
}

// This is the height of the full height sider minus the header
const siderHeight = 'calc(100vh - 64px)';
export const projectCardHeight = 'calc(100vh - 64px - 42px)';
export const projectCardWidth = 'calc(100vw - 254px)';

const FullHeightCol = styled(Col)`
  min-height: 100% !important;
`;

const StyledLayoutContent = styled(Layout.Content)`
  min-height: 100% !important;
`;

const ProjectsLayout: React.FC<ProjectsLayoutProps> = ({ children }) => {
  const projects = useProjects();
  const types = useProjectTypes();
  const solutionTypes = useProjectSolutionTypes();
  const [pinnedProjects] = useLocalStorageState<string[]>('pinned-projects');
  const [type, setType] = useSearchParameter('type');
  const [actualType, setActualType] = useState(type);
  const [active, setActive] = useSearchParameter('active');
  const typeFilteredProjects = (
    projects.data?.filter((p) => {
      return actualType ? p.projectType.idProjectType.toString() === actualType : true;
    }) || []
  )
    .sort((a, b) => Comparator.lexicographicalComparison(a.name, b.name))
    .sort((a, b) => {
      const aN = pinnedProjects?.includes(a.idProject.toString()) ? 1 : 0;
      const bN = pinnedProjects?.includes(b.idProject.toString()) ? 1 : 0;
      return bN - aN;
    });
  const loading = projects.isLoading || types.isLoading || solutionTypes.isLoading;

  const activeProject =
    typeFilteredProjects?.find((p) => p.idProject.toString() === active) ??
    (typeFilteredProjects.length > 0 ? typeFilteredProjects.find((proj) => !proj.isArchived) : undefined);

  useEffect(() => {
    if (!active && activeProject && String(activeProject?.projectType?.idProjectType) === type) {
      // This fallback is made for the default one to be set only when there is no inferred active project.
      const activeProjectId = extractProjectId(location.pathname) || activeProject.idProject.toString();
      setActive(activeProjectId);
    }
  }, [active, activeProject, setActive, type]);

  useEffect(() => {
    if (!type && activeProject) {
      setType(activeProject.projectType.idProjectType.toString());
    }
  }, [type, activeProject, setType]);

  // We shall skip passing the undefined search param since it triggers a useless rerender
  useEffect(() => {
    if (!type) {
      return;
    }

    setActualType(type);
  }, [type]);

  return (
    <Skeleton loading={loading}>
      <Layout style={{ position: 'relative', marginTop: 0 }}>
        <Row wrap={false}>
          <Col>
            <Layout.Sider
              width={240}
              style={{
                position: 'sticky',
                marginRight: 8,
                overflowY: 'auto',
                overflowX: 'hidden',
                height: siderHeight
              }}
            >
              <ProjectsMenu active={active} setActive={setActive} selectableProjects={typeFilteredProjects} />
            </Layout.Sider>
          </Col>
          <FullHeightCol>
            <StyledLayoutContent>{children}</StyledLayoutContent>
          </FullHeightCol>
        </Row>
      </Layout>
    </Skeleton>
  );
};

export default ProjectsLayout;
