import { useState } from 'react';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, Flex, Select, Space, Tooltip, Typography } from 'antd';
import styled from 'styled-components';

import useAssignProjectMember from '../../administration/hooks/useAssignProjectMember';
import useProjectRoles from '../../administration/hooks/useProjectRoles';
import { useDeleteProjectAccessRequest } from '../hooks/useDeleteProjectAccessRequest';

import type { Project, ProjectMember, ProjectMemberAccessRequest } from '../../../api';

const StyledNameText = styled(Typography.Text)`
  width: 200px;
  max-width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
  vertical-align: middle;
`;

const DeclineUserButton = styled(Button)`
  &:hover {
    background: ${({ theme }) => theme.colorError} !important;
    color: ${({ theme }) => theme.colorWhite} !important;
    border-color: ${({ theme }) => theme.colorError} !important;
  }
`;

const SuccessButton = styled(Button)`
  ${({ disabled, theme }) => {
    if (disabled) return '';

    return `
      color: ${theme.colorSuccess};
      border-color: ${theme.colorSuccess} !important;

      &:hover, &:focus {
        background-color: rgb(from ${theme.colorSuccess} r g b / 0.8) !important;
        color: white !important;
      }
    `;
  }}
`;

export const ProjectAccessRequestListItem = (props: { project: Project; accessRequest: ProjectMemberAccessRequest }) => {
  const decline = useDeleteProjectAccessRequest();
  const roles = useProjectRoles();
  const add = useAssignProjectMember();
  const [selectedRole, setSelectedRole] = useState(undefined);

  const Selector = () => {
    return (
      <Select
        className={`project-access-request-role-selector-${props.project.idProject}-${props.accessRequest.idMember}`}
        loading={!roles.isSuccess}
        style={{ width: 240 }}
        value={selectedRole}
        onChange={setSelectedRole}
        placeholder="Select Role..."
        options={roles.data?.map((r) => {
          return { value: r.idProjectRole, label: r.name };
        })}
      />
    );
  };

  const SaveRole = () => {
    const role = roles.data?.find((r) => r.idProjectRole === selectedRole);
    const pmToAssign: ProjectMember = {
      member: {
        idMember: props.accessRequest.idMember,
        email: props.accessRequest.email,
        name: props.accessRequest.name
      },
      roles: role ? [role] : []
    };

    return (
      <SuccessButton
        type="default"
        disabled={!role}
        loading={add.isLoading}
        onClick={() => {
          add.mutate([props.project.idProject.toString(), pmToAssign]);
        }}
        icon={<CheckOutlined />}
      >
        Approve
      </SuccessButton>
    );
  };

  const DeclineUser = () => {
    return (
      <DeclineUserButton
        danger
        onClick={() => {
          decline.mutate([props.project, props.accessRequest.idMember]);
        }}
        loading={decline.isLoading}
        icon={<CloseOutlined />}
        key="dismiss"
      >
        Decline
      </DeclineUserButton>
    );
  };

  return (
    <Flex align="center" justify="space-between">
      <Space>
        <Tooltip title={props.accessRequest.name}>
          <StyledNameText>{`${props.accessRequest.name}`}</StyledNameText>
        </Tooltip>
        <Selector />
      </Space>

      <Space>
        <SaveRole />
        <DeclineUser />
      </Space>
    </Flex>
  );
};
