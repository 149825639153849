import { Col, Space } from 'antd';
import { useContext } from 'react';

import { NotificationMenuButton } from '../../notifications/components/NotificationMenuButton';
import { InfoMenu } from './InfoMenu';
import { useSession } from '../../../contexts/session/hooks/useSession';
import { PacTSContext } from '../../../state/store';
import { AccountDropdown } from './AccountDropdown';


export const UserMenu = () => {
  const [state] = useContext(PacTSContext);
  const session = useSession(state);

  const loggedIn = session.state === 'loggedIn';

  return (
    <Space size={18}>
      {loggedIn && (
        <Col sm={540} md={720} lg={960} xl={1140}>
          <NotificationMenuButton />
        </Col>
      )}

      {loggedIn && (
        <Col sm={540} md={720} lg={960} xl={1140}>
          <InfoMenu />
        </Col>
      )}
      {loggedIn && (
        <Col sm={540} md={720} lg={960} xl={1140}>
          <AccountDropdown />
        </Col>
      )}
    </Space>
  );
};
