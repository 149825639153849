import { InfoCircleOutlined } from '@ant-design/icons';
import { Button, Flex, Tooltip } from 'antd';
import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useGrafanaUrl } from '../hooks/useGrafanaUrl';
import { useStyles } from '../../theme/useStyles';
import { useIsDevVersion } from '../../../contexts/navigation/hooks/useIsDevVersion';
import { Project } from '../../../api/engineering/domain/types';
import { MetricClickIds } from '../../../contexts/metrics/constants';
import useSubmitMetric from '../../../contexts/metrics/hooks/useMetrics';
import { AnimatedLoadingIcon } from '../../../contexts/shared/components/icons/AnimatedLoadingIcon';


const InfoIcon = styled(InfoCircleOutlined)`
    font-size: 20px;
`;

export const GrafanaDashboardButton = (props: { target: Project }) => {
    const isDev = useIsDevVersion();
    const grafanaUrl = useGrafanaUrl(props.target.idProject.toString(), props.target.name);
    const error = grafanaUrl.error ? (grafanaUrl.error as AxiosError) : undefined;
    const token = useStyles();

    const { submitClick } = useSubmitMetric();

    const [errorState, setErrorState] = useState(error);

    useEffect(() => {
        if (grafanaUrl.error) {
        setErrorState(grafanaUrl.error as AxiosError);
        }
    }, [grafanaUrl.error, setErrorState]);

    useEffect(() => {
        setErrorState(undefined);
    }, [props.target, setErrorState]);

    const GoToGrafanaButton = () => {
        return (
        <Flex gap={8}>
            <Button type="primary" onClick={() => window.open(`https://${isDev ? 'dev.' : ''}kpi.pacts.cloud/grafana`)}>
            Log in to Grafana
            </Button>
            <Tooltip
                title="Please log in to Grafana to get access to the project's KPI dashboards."
                trigger={['click', 'hover']}
            >
                <InfoIcon />
            </Tooltip>
        </Flex>
        );
    };

    const dashboardUrl = grafanaUrl.data || '';
    const disabled = !grafanaUrl.isSuccess || dashboardUrl === '';
    const hasError = errorState !== undefined;
    const isGrafanaLoginRequiredError = disabled && errorState?.response?.status === 406;
    const isDashboardNotExistingError = disabled && errorState?.response?.status === 404;
    const isGenericError = disabled && hasError && !isGrafanaLoginRequiredError && !isDashboardNotExistingError;
    const showButton = !grafanaUrl.isLoading && !isGrafanaLoginRequiredError;

    const DashboardButton = () => {
        if (isDashboardNotExistingError || isGenericError) {
            return (
                <Tooltip
                    title="Project KPI dashboard is not available for this project. You can create a request to have KPI dashboards for this project."
                    placement='bottom'
                    trigger={['click', 'hover']}
                >
                    <Button disabled>Go to KPI dashboards</Button>
                </Tooltip>
            );
        }

        return (
            <Button
                onClick={() => {
                    submitClick({ operationId: MetricClickIds.projectKPIsOpen }).withProjectContext(props.target);
                    window.open(dashboardUrl);
                }}
                type='primary'
                disabled={disabled}
            >
                Go to KPI dashboards
            </Button>
        )
    }

    return (
        <>
            {grafanaUrl.isLoading && <AnimatedLoadingIcon style={{ marginRight: 2, color: token.colorTextDisabled }} />}
            {isGrafanaLoginRequiredError && <GoToGrafanaButton />}
            {showButton && <DashboardButton />}
        </>
    );
};
