import { Card } from 'antd';
import { projectCardWidth } from './ProjectsLayout';
import BreadcrumbsContainer from './BreadcrumbsContainer';
import { BreadcrumbItemType, BreadcrumbSeparatorType } from 'antd/es/breadcrumb/Breadcrumb';
import { useEffect, useState } from 'react';
import { useSearchParameter } from '../../../contexts/navigation/hooks';
import { useProjectTypes } from '../hooks';

interface ProjectContentWrapperProps {
  isLoading?: boolean;
  title?: string;
  extra?: React.ReactNode;
  children: React.ReactNode;
  breadcrumbItems: Partial<BreadcrumbItemType & BreadcrumbSeparatorType>[];
}

const ProjectContentWrapper: React.FC<ProjectContentWrapperProps> = ({ breadcrumbItems = [], title, isLoading, children, extra }) => {
  const [projectType] = useSearchParameter('type');
  const [actualProjectType, setActualProjectType] = useState(projectType);
  const projectTypes = useProjectTypes();
  const projectBreadcrumbByType = projectTypes.data?.reduce((acc: Record<string, any>, type) => {
    acc[String(type.idProjectType)] = {
      title: type.name
    };
    return acc;
  }, {});
  const [breadcrumbsContainerRef, setBreadcrumbsContainerRef] = useState<HTMLDivElement | null>(null);
  const parentBreadcrumb = projectBreadcrumbByType?.[String(actualProjectType) || ''];
  const projectBreadcrumbItems = !!parentBreadcrumb ? [parentBreadcrumb, ...breadcrumbItems] : breadcrumbItems;

  useEffect(() => {
    if (!projectType) {
      return;
    }

    setActualProjectType(projectType);
  }, [projectType]);

  return (
    <>
      <div ref={(ref) => setBreadcrumbsContainerRef(ref)}>{breadcrumbItems.length > 0 && <BreadcrumbsContainer items={projectBreadcrumbItems} />}</div>
      <Card
        style={{
          width: projectCardWidth,
          overflow: 'scroll',
          height: `calc(100vh - 64px - ${breadcrumbsContainerRef?.clientHeight || 0}px)`
        }}
        title={title}
        bordered={false}
        extra={extra}
        loading={isLoading}
      >
        {children}
      </Card>
    </>
  );
};
export default ProjectContentWrapper;
