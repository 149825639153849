import { useState, useEffect } from 'react';
import { Button, Form } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useProjectSoftwareAppVersions } from '../hooks/useProjectSoftwareappVersions';
import { useUpdateProjectSoftwareApp } from '../hooks/useUpdateProjectSoftwareApp';
import { UpdateSoftwareAppForm } from './UpdateSoftwareAppForm';
import { SoftwareApp } from '../../../api/engineering/domain/types';
import { ShiftedDrawer } from '../../shared/components/ShiftedDrawer';
import { usePermissions } from '../../../contexts/session/hooks/usePermissions';

export type UpdateSoftwareAppProps = {
  projectId: string;
  softwareApp: SoftwareApp;
};

export const UpdateProjectSoftwareApp = (props: UpdateSoftwareAppProps) => {
  const [drawerVisible, setDrawerVisible] = useState(false);
  const updateSoftwareApp = useUpdateProjectSoftwareApp();
  const versions = useProjectSoftwareAppVersions(props.projectId, props.softwareApp.idSoftwareApp?.toString() || '');
  const permission = usePermissions({
    softwareAppId: props.softwareApp.idSoftwareApp.toString(),
    projectId: props.projectId
  });

  let okHandler: () => any;
  let resetHandler: () => any;
  const [form] = Form.useForm();

  useEffect(() => {
    if (updateSoftwareApp.isSuccess) {
      setDrawerVisible(false);
      form.resetFields();
    }
  }, [form, updateSoftwareApp.isSuccess]);

  if (!permission.engineeringSvc$updateProjectSoftwareApp) return null;

  return (
    <>
      <Button
        icon={<EditOutlined />}
        loading={updateSoftwareApp.isLoading}
        onClick={() => {
          setDrawerVisible(true);
        }}
      >
        Edit
      </Button>

      <ShiftedDrawer
        title="Edit Software App"
        destroyOnClose
        loading={updateSoftwareApp.isLoading}
        open={drawerVisible}
        isFormDrawer
        onOk={() => {
          okHandler();
        }}
        onCancel={() => {
          resetHandler();
          setDrawerVisible(false);
        }}
      >
        <UpdateSoftwareAppForm
          initial={props.softwareApp}
          ok={(ok) => {
            okHandler = ok;
          }}
          reset={(cancel) => {
            resetHandler = cancel;
          }}
          form={form}
          onFinish={async (ps) => {
            return new Promise((resolve, reject) => {
              updateSoftwareApp
                .mutateAsync([props.projectId, ps])
                .then(() => {
                  setDrawerVisible(false);
                  resolve();
                })
                .catch(() => {
                  reject();
                });
            });
          }}
          versions={versions.data || []}
        />
      </ShiftedDrawer>
    </>
  );
};
