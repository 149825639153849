import { useState } from 'react';
import { Button, Col, Form, Input, Row } from 'antd';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { ShiftedDrawer } from '../../shared/components/ShiftedDrawer';
import { useDeploymentEnvironments } from '../hooks/useDeploymentEnvironments';
import { useAddDeploymentEnvironment } from '../hooks/useAddDeploymentEnvironment';
import { useDeleteDeploymentEnvironment } from '../hooks/useDeleteDeploymentEnvironment';
import { useUpdateDeploymentEnvironments } from '../hooks/useUpdateDeploymentEnvironments';
import EnvironmentCard from './EnvironmentCard';
import { useProject } from '../../../contexts/projects/hooks/useProject';
import { useInAppNavigate, useSearchParameter } from '../../../contexts/navigation/hooks';
import { usePermissions } from '../../../contexts/session';
import { Comparator } from '../../../domain';
import { NoEnvironmentsMessage } from './NoEnvironmentsMessage';
import ProjectContentWrapper from '../../../contexts/projects/components/ProjectContentWrapper';

import type { Environment } from '../../../api';

const StyledTextArea = styled(Input.TextArea)`
  min-height: 150px !important;
  max-height: 450px !important;
`;

const DeploymentEnvironments: React.FC = () => {
  const params = useParams() as any;
  const [projectType] = useSearchParameter('type');
  const navigate = useInAppNavigate();
  const projectId = parseInt(params.projectId);
  const permissions = usePermissions({ projectId: params.projectId });
  const project = useProject(projectId.toString());
  const depEnvs = useDeploymentEnvironments(projectId);
  const addDepEnv = useAddDeploymentEnvironment();
  const deleteDepEnv = useDeleteDeploymentEnvironment();
  const updateDepEnv = useUpdateDeploymentEnvironments();
  const [formModalProps, setFormModalProps] = useState({
    open: false,
    type: 'create'
  });
  const breadcrumbItems = [{ title: project.data?.name, url: `/projects?active=${projectId}&type=${projectType}` }, { title: 'Environments' }];
  const [formValues, setFormValues] = useState<Record<string, any> | null>(null);

  const [form] = Form.useForm<Environment>();

  const isLoading = depEnvs.isLoading || project.isLoading;
  const hasNoEnvironments = !isLoading && (depEnvs.data?.length ?? 0) < 1;

  const okHandler = () => {
    form.submit();
  };

  const cancelHandler = () => {
    form.resetFields();
    setFormModalProps({ ...formModalProps, open: false });
  };

  const handleUpdateEnvironment = (env: Environment) => {
    setFormValues({
      name: env.name,
      description: env.description,
      id: env.id,
      mvccId: env.mvccId
    });

    form.setFieldsValue({
      name: env.name,
      description: env.description
    });

    setFormModalProps({ type: 'update', open: true });
  };

  const handleNavigateToDeployments = (envId: string) => {
    navigate(`/projects/${projectId}/deployments/${envId}`);
  };

  const closeModal = () => {
    setFormModalProps((prevProps) => ({ ...prevProps, open: false }));
    setFormValues(null);
  };

  const handleFinish = async (v: Environment) => {
    if (formModalProps.type === 'create') {
      addDepEnv
        .mutateAsync([projectId, v.name, v.description])
        .then(() => {
          closeModal();
          form.resetFields();
        })
        .catch(() => {});
    } else {
      updateDepEnv
        .mutateAsync([projectId, formValues?.id, formValues?.mvccId, v.name, v.description])
        .then(() => {
          closeModal();
          form.resetFields();
        })
        .catch(() => {});
    }

    setFormValues(null);
  };

  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 }
  };

  return (
    <ProjectContentWrapper
      title="Environments"
      breadcrumbItems={breadcrumbItems}
      extra={
        permissions.deploymentSvc$addEnvironment ? (
          <Button onClick={() => setFormModalProps({ type: 'create', open: true })} type="primary">
            New environment
          </Button>
        ) : null
      }
      isLoading={isLoading}
    >
      {hasNoEnvironments ? <NoEnvironmentsMessage hideCreateHelp projectId={projectId} /> : null}
      <Row gutter={[16, 16]}>
        {depEnvs.data
          ?.sort((a, b) => Comparator.lexicographicalComparison(a.name, b.name))
          .map((env) => (
            <Col key={env.id}>
              <EnvironmentCard
                canEdit={permissions.deploymentSvc$updateEnvironment}
                canDelete={permissions.deploymentSvc$deleteEnvironment}
                env={env}
                onNavigateToDeployments={handleNavigateToDeployments}
                onUpdateEnvironment={handleUpdateEnvironment}
                projectId={projectId}
                onConfirmDelete={() => {
                  deleteDepEnv.mutate([projectId, env.id, env.mvccId]);
                }}
              />
            </Col>
          ))}
      </Row>

      <ShiftedDrawer
        title={`${formModalProps.type === 'create' ? 'Create new' : 'Edit'} environment`}
        open={formModalProps.open}
        destroyOnClose
        isFormDrawer
        onCancel={cancelHandler}
        onOk={okHandler}
      >
        <Form {...layout} name="add__deployment_environment_form" labelAlign="left" form={form} onFinish={handleFinish}>
          <Form.Item name="name" label="Name" rules={[{ required: true, message: 'Required' }]}>
            <Input />
          </Form.Item>

          <Form.Item name="description" label="Description" rules={[{ required: true, message: 'Required' }]}>
            <StyledTextArea />
          </Form.Item>
        </Form>
      </ShiftedDrawer>
    </ProjectContentWrapper>
  );
};

export default DeploymentEnvironments;
