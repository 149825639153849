import { useEffect, useState } from 'react';
import { InfoCircleOutlined, OrderedListOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import { ExpandableMenuItem } from '../../shared/components/ExpandableMenuItem';
import { SoftwareAppSelection } from '../../../domain';
import { ExpandableMenu } from '../../shared/components/ExpandableMenu';
import type { ScopedSoftwareApp } from '../hooks/scopedSoftwareApp';
import { SoftwareAppsOpenInToolManagerButton } from './SoftwareAppsOpenInToolManagerButton';
import { SubscriptionPopup } from '../../notifications/components/SubscriptionPopup';
import type { Project } from '../../../api/engineering/domain/types';
import useSubmitMetric from '../../../contexts/metrics/hooks/useMetrics';
import { MetricClickIds } from '../../../contexts/metrics/constants';
import { DownloadContextMenuButton } from '../../shared/components/Download/components/DownloadContextMenuButton';
import { usePermissions } from '../../../contexts/session/hooks/usePermissions';
import { useSearchParameter } from '../../navigation/hooks/useSearchParameter';
import { SoftwareAppVersionsDrawer } from './SoftwareAppVersionsDrawer';
import { SoftwareAppDetailsDrawer } from './SoftwareAppDetailsDrawer';

export const ProjectSoftwareAppsActionMenu = (props: { swa: ScopedSoftwareApp; project: Project; currentSelection: SoftwareAppSelection | undefined }) => {
  const [openVersionDrawer, setOpenVersionDrawer] = useState(false);
  const [openDetailsDrawer, setOpenDetailsDrawer] = useState(false);
  const [openDrawerVersionIdParam, setOpenDrawerVersionIdParam] = useSearchParameter('open_app_versions');
  const [openDrawerDetailsIdParam, setOpenDrawerDetailsIdParam] = useSearchParameter('open_app_details');

  const metrics = useSubmitMetric();
  const permissions = usePermissions({
    projectId: props.project.idProject.toString(),
    softwareAppId: props.currentSelection?.app.idSoftwareApp.toString(),
    idSoftwareAppVersion: props.currentSelection?.version.idSoftwareAppVersion.toString()
  });

  const canGetVersions =
    permissions.engineeringSvc$getProjectSoftwareAppVersions || permissions.engineeringSvc$getProjectSoftwareAppVersion$specific().length > 0;

  // When loading a page check if the drawer should be open based on the search params.
  useEffect(() => {
    if (!openVersionDrawer && canGetVersions && openDrawerVersionIdParam && openDrawerVersionIdParam === props.swa.idSoftwareApp.toString()) {
      setOpenVersionDrawer(true);
    }

    if (!openDetailsDrawer && openDrawerDetailsIdParam && openDrawerDetailsIdParam === props.swa.idSoftwareApp.toString()) {
      setOpenDetailsDrawer(true);
    }
  }, [openVersionDrawer, openDetailsDrawer, canGetVersions, openDrawerVersionIdParam, openDrawerDetailsIdParam, props.swa.idSoftwareApp]);

  const handleOpenVersionDrawer = () => {
    setOpenDrawerVersionIdParam(props.swa.idSoftwareApp.toString());
    setOpenVersionDrawer(true);
  };

  const handleCloseVersionDrawer = () => {
    setOpenVersionDrawer(false);
    setOpenDrawerVersionIdParam('');
  };

  const handleOpenDetailsDrawer = () => {
    setOpenDrawerDetailsIdParam(props.swa.idSoftwareApp.toString());
    setOpenDetailsDrawer(true);
  };

  const handleCloseDetailsDrawer = () => {
    setOpenDetailsDrawer(false);
    setOpenDrawerDetailsIdParam('');
  };

  return (
    <>
      <Space>
        <SubscriptionPopup
          type="text"
          title={props.swa.name}
          topics={[{ key: `projects/${props.project.idProject}/apps/${props.swa.idSoftwareApp}/**`, title: props.swa.name }]}
        />
        <ExpandableMenu textType id={`project-app-actions-${props.project.idProject}-${props.swa.idSoftwareApp}`}>
          {permissions.webui$showComponentDetails && (
            <ExpandableMenuItem
              icon={<InfoCircleOutlined />}
              onClick={() => {
                metrics
                  .submitClick({
                    operationId: MetricClickIds.componentDetails
                  })
                  .withAppContext(props.swa, props.project);

                handleOpenDetailsDrawer();
              }}
            >
              Details
            </ExpandableMenuItem>
          )}
          {canGetVersions && (
            <ExpandableMenuItem
              icon={<OrderedListOutlined />}
              onClick={() => {
                metrics
                  .submitClick({
                    operationId: MetricClickIds.componentVersions
                  })
                  .withAppContext(props.swa, props.project);

                handleOpenVersionDrawer();
              }}
            >
              Versions
            </ExpandableMenuItem>
          )}
          {props.currentSelection && (
            <DownloadContextMenuButton artifact={props.currentSelection.app} version={props.currentSelection.version} projectId={props.project.idProject} />
          )}
          {props.currentSelection && <SoftwareAppsOpenInToolManagerButton selection={props.currentSelection} project={props.project} />}
        </ExpandableMenu>
      </Space>
      {permissions.webui$showComponentDetails && (
        <SoftwareAppDetailsDrawer app={props.swa} project={props.project} onClose={handleCloseDetailsDrawer} open={openDetailsDrawer} />
      )}
      {canGetVersions && <SoftwareAppVersionsDrawer app={props.swa} project={props.project} onClose={handleCloseVersionDrawer} open={openVersionDrawer} />}
    </>
  );
};
