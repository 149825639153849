import { EditOutlined, MoreOutlined } from '@ant-design/icons';
import { Card, Flex, Skeleton, Tag, Typography } from 'antd';
import { useDeploymentPlans } from '../hooks/useDeploymentPlans';
import styled from 'styled-components';
import { Environment } from '../../../api';
import { ExpandableMenuItem } from '../../../contexts/shared/components/ExpandableMenuItem';
import { StopEventPropagationWrapper } from '../../../contexts/shared/components/StopEventPropagationWrapper';
import { ExpandableMenu } from '../../../contexts/shared/components/ExpandableMenu';
import { ConfirmationButton } from '../../../contexts/shared/components/ConfirmationButton';
import { formatDateTime } from '../../../contexts/shared/components/formatDate';

interface EnvironmentCardProps {
  env: Environment;
  projectId: number;
  canEdit: boolean;
  canDelete: boolean;
  onNavigateToDeployments: (id: string) => void;
  onUpdateEnvironment: (env: Environment) => void;
  onConfirmDelete: () => void;
}

const FullWidthCard = styled(Card)`
  width: 380px;
`;

const LoadingTag = styled(Skeleton.Input)`
  margin-right: 4px;
`;

const DescriptionItemWrapper = styled.div`
  max-width: 50%;
`;

const EnvironmentCard: React.FC<EnvironmentCardProps> = ({
  env,
  onNavigateToDeployments,
  onUpdateEnvironment,
  onConfirmDelete,
  projectId,
  canEdit,
  canDelete
}) => {
  const deploymentPlans = useDeploymentPlans(projectId, env.id);
  const nullSafePlantList = deploymentPlans.data || [];
  const activePlans =
    nullSafePlantList
      .filter((plan) => !plan.locked)
      .sort((a, b) => {
        return Date.parse(b.createdAt!) - Date.parse(a.createdAt!);
      }) || [];
  const activePlan = activePlans?.[0];

  const tagComponent = (isInstalled: boolean, activePlanExists: boolean) => {
    if (isInstalled) {
      return <Tag color="success">All installed</Tag>;
    } else if (!activePlanExists) {
      return <Tag>No active deployment</Tag>;
    } else {
      return <Tag color="warning">Pending installations</Tag>;
    }
  };

  const isLoading = deploymentPlans.isLoading;

  const DescriptionItem = (diProps: { text: string | undefined }) => (
    <DescriptionItemWrapper>
      <Typography.Text ellipsis={{ tooltip: diProps.text || '-' }} strong>
        {diProps.text || '-'}
      </Typography.Text>
    </DescriptionItemWrapper>
  );

  const flexItemOptions = {
    justify: 'flex-start',
    gap: 4
  };

  return (
    <>
      <FullWidthCard
        id={`env-card-${env.id}`}
        size="small"
        loading={isLoading}
        hoverable
        onClick={() => onNavigateToDeployments(env.id)}
        title={<Typography.Text strong>{env.name}</Typography.Text>}
        extra={
          <StopEventPropagationWrapper>
            {isLoading ? <LoadingTag size="small" active /> : tagComponent(activePlan?.fullyInstalled, activePlan != null)}
            <ExpandableMenu textType id={`update-deployment-environment-${env.id}`} icon={<MoreOutlined />} disabled={isLoading}>
              {canEdit ? (
                <ExpandableMenuItem
                  icon={<EditOutlined />}
                  disabled={isLoading}
                  onClick={() => {
                    onUpdateEnvironment(env);
                  }}
                >
                  Edit
                </ExpandableMenuItem>
              ) : null}
              {canDelete ? (
                <ConfirmationButton
                  danger
                  disabled={isLoading}
                  title={`Are you sure you want to delete the ${env.name} environment?`}
                  caption="Delete"
                  description="All deployment plans associated with this environment will also be deleted."
                  onOk={onConfirmDelete}
                />
              ) : null}
            </ExpandableMenu>
          </StopEventPropagationWrapper>
        }
      >
        <Flex vertical gap={'0.6em'}>
          <Flex {...flexItemOptions}>
            <Typography.Text>Configuration version:</Typography.Text>
            <DescriptionItem text={activePlan?.release?.releaseName} />
          </Flex>
          <Flex {...flexItemOptions}>
            <Typography.Text>Configuration name:</Typography.Text>
            <DescriptionItem text={activePlan?.release.configName} />
          </Flex>
          <Flex {...flexItemOptions}>
            <Typography.Text>Deployment name:</Typography.Text>
            <DescriptionItem text={activePlan?.name} />
          </Flex>
          <Flex {...flexItemOptions}>
            <Typography.Text>Deployment creation:</Typography.Text>
            <DescriptionItem text={activePlan?.createdAt ? formatDateTime(new Date(activePlan?.createdAt)) : undefined} />
          </Flex>
          <Flex {...flexItemOptions}>
            <Typography.Text>Deployment creator:</Typography.Text>
            <DescriptionItem text={activePlan?.createdByName} />
          </Flex>
        </Flex>
      </FullWidthCard>
    </>
  );
};

export default EnvironmentCard;
