import { isEqual } from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Project, SoftwareAppVersion } from '../../../api/engineering/domain/types';
import { SoftwareAppSelection } from '../../../domain/softwareAppsSelections';
import { ScopedSoftwareApp } from '../hooks/scopedSoftwareApp';
import { getSelectionMapKey, SelectionMap, SoftwareAppsSubTable } from './SoftwareAppsSubTable';
import { SegmentedLabeledOption, SegmentedValue } from 'antd/es/segmented';
import { Space, Tooltip, Typography } from 'antd';
import { Comparator } from '../../../domain';
import { useLocalStorageState } from '../../../contexts/shared/hooks/useLocalStorageState';
import { useSearchParameter } from '../../../contexts/navigation/hooks/useSearchParameter';

const prefferedCategoryListKey = 'preferred-app-category-list-key';
const allCategoriesLabel = 'All Categories';

export const SoftwareAppsSubTableProvider = (props: {
  project?: Project;
  selected: SoftwareAppSelection[];
  initiallySelected: SoftwareAppSelection[];
  apps: ScopedSoftwareApp[];
  showBundleItemsOnly?: boolean;
  hideCheckboxes?: boolean;
  onDirty?: (dirty: boolean) => any;
  onSelect: (selected: SoftwareAppSelection[]) => any;
  hasTopOffset?: boolean;
}) => {
  const [filteredApps, setFilteredApps] = useState([] as ScopedSoftwareApp[]);
  const [preferredTabKey, setPrefferedTabKey] = useLocalStorageState<string>(prefferedCategoryListKey, undefined);
  const [activeTabKey, setActiveTabKey] = useSearchParameter('tab', preferredTabKey || undefined);
  const selectionMap = useRef({} as SelectionMap);
  const initialSelectionMap = useRef({} as SelectionMap);

  const getCategoryName = (app: ScopedSoftwareApp): string => {
    return app.categories && app.categories.length > 0 ? app.categories[0].name : 'unknown';
  };

  useEffect(() => {
    selectionMap.current = {};
    [...props.apps].forEach((sa) => {
      if (sa.latestVersion) {
        selectionMap.current[getSelectionMapKey(sa)] = {
          app: sa,
          version: sa.latestVersion
        };
      }
    });
    props.initiallySelected.forEach((t) => {
      if (selectionMap.current[getSelectionMapKey(t.app)]) {
        selectionMap.current[getSelectionMapKey(t.app)].version = t.version;
      }
    });
    props.initiallySelected.forEach((t) => {
      initialSelectionMap.current[getSelectionMapKey(t.app)] = t;
    });
  }, [props.apps, props.initiallySelected]);

  useEffect(() => {
    props.selected.forEach((t) => {
      if (selectionMap.current[getSelectionMapKey(t.app)]) {
        selectionMap.current[getSelectionMapKey(t.app)].version = t.version;
      }
    });
  }, [props.selected]);

  const groupedSoftwareApps = useMemo(() => {
    let computedSoftwareApps: Record<string, ScopedSoftwareApp[]> = {
      [allCategoriesLabel]: filteredApps
    };

    if (props.apps) {
      computedSoftwareApps = {
        ...computedSoftwareApps,
        ...filteredApps.reduce(
          (rv, x) => {
            const category = getCategoryName(x);
            (rv[category] = rv[category] || []).push(x);
            return rv;
          },
          {} as Record<string, ScopedSoftwareApp[]>
        )
      };
    }

    return computedSoftwareApps;
  }, [props.apps, filteredApps]);

  useEffect(() => {
    if (!props.onDirty) {
      return;
    }
    props.onDirty(
      !isEqual(
        props.selected.map((s) => s.version.idSoftwareAppVersion + s.app.scope).sort(),
        props.initiallySelected.map((s) => s.version.idSoftwareAppVersion + s.app.scope).sort()
      )
    );
  }, [props.selected, props.initiallySelected, props]);

  useEffect(() => {
    setFilteredApps(
      [...props.apps].filter(
        (t) =>
          props.initiallySelected.find((st) => st.app.idSoftwareApp === t.idSoftwareApp && st.app.scope === t.scope) ||
          props.selected.find((st) => st.app.idSoftwareApp === t.idSoftwareApp && st.app.scope === t.scope) ||
          !props.showBundleItemsOnly
      )
    );
  }, [props.apps, props.selected, props.showBundleItemsOnly, props.initiallySelected]);

  useEffect(() => {
    if (groupedSoftwareApps[activeTabKey ?? ''] == null && Object.keys(groupedSoftwareApps).length > 1) {
      const defaultKey = Object.keys(groupedSoftwareApps)[0];

      setActiveTabKey(defaultKey);
    }
  }, [groupedSoftwareApps, activeTabKey, setActiveTabKey]);

  const onSelectionUpdate = (selectedList: SoftwareAppSelection[]) => {
    props.onSelect(selectedList.filter(Boolean));
  };

  const handleActiveTabChange = (key: SegmentedValue) => {
    const keyStr = key.toString();

    setActiveTabKey(keyStr);
    setPrefferedTabKey(keyStr);
  };

  const updateFromProps = (sa: ScopedSoftwareApp) => {
    if (props.selected.find((swa) => swa.app.idSoftwareApp === sa.idSoftwareApp && swa.app.scope === sa.scope)) {
      const selected = props.selected.map((sr) => selectionMap.current[getSelectionMapKey(sr.app)]);
      onSelectionUpdate(selected);
    } else {
      // Always update selection map to ensure rerender of the list even if target of non selected app was changed
      onSelectionUpdate([...props.selected]);
    }
  };
  const updateSelectedSoftwareAppVersion = (sa: ScopedSoftwareApp, sv: SoftwareAppVersion) => {
    selectionMap.current[getSelectionMapKey(sa)] = {
      app: sa,
      version: sv
    };
    updateFromProps(sa);
  };

  const rowSelection = () => {
    return {
      selectedRowKeys: props.selected.map((t) => getSelectionMapKey(t.app)),
      onChange: (selectedRowKeys: string[], selectedRows: ScopedSoftwareApp[]) => {
        // Get all elements in currently selected tab
        const keysOfCurrentTab = groupedSoftwareApps[activeTabKey ?? allCategoriesLabel].map(getSelectionMapKey);
        // Remove all elements of current tab from selection list
        const newSelectedApps = props.selected.filter((app) => !keysOfCurrentTab.includes(getSelectionMapKey(app.app)));
        // Get elements from selection map
        const toPush = selectedRows.map((sr) => selectionMap.current[getSelectionMapKey(sr)]);
        // Add back to selected elements
        newSelectedApps.push(...toPush);
        onSelectionUpdate(newSelectedApps);
      },
      getCheckboxProps: (record: any) => ({
        name: record.name
      })
    };
  };

  // Disabled numbering until we get a proper specification
  // TODO: enable once we know how it is supposed to behave

  // const changes = useMemo(() => {
  //   return xorBy(props.selected, props.initiallySelected, (s) => `${getSelectionMapKey(s.app)}-${s.version.idSoftwareAppVersion}`).map((s) =>
  //     getSelectionMapKey(s.app)
  //   );
  // }, [props.selected, props.initiallySelected]);

  const tabOptions: SegmentedLabeledOption[] = useMemo(() => {
    const tabs = Object.keys(groupedSoftwareApps).map((key, idx) => {
      // const keysOfCurrentTab = groupedSoftwareApps[key].map(getSelectionMapKey);
      // const changesInCategory = keysOfCurrentTab.filter((app) => changes.includes(app));
      // const changesCount = changesInCategory.length;
      return {
        value: key,
        label: (
          <Space size={0}>
            <div style={{ maxWidth: 164, marginBottom: 4 }}>
              <Tooltip title={key}>
                <Typography.Text id={`segmented-${idx}`} style={{ margin: 0, padding: 0 }} ellipsis>
                  {key}
                </Typography.Text>
              </Tooltip>
            </div>
          </Space>
        )
      };
    });
    const sortedTabs = [tabs[0], ...tabs.slice(1).sort((a, b) => Comparator.lexicographicalComparison(a.value, b.value))];

    return sortedTabs;
  }, [groupedSoftwareApps]);

  if (activeTabKey == null) {
    return null;
  }
  if (groupedSoftwareApps[activeTabKey] == undefined) {
    return null;
  }

  return (
    <SoftwareAppsSubTable
      hasTopOffset={props.hasTopOffset}
      activeTabKey={activeTabKey}
      onActiveTabChange={handleActiveTabChange}
      tabOptions={tabOptions}
      hideCheckboxes={props.hideCheckboxes}
      initialSelectionMap={initialSelectionMap.current}
      project={props.project}
      rowSelection={rowSelection}
      selectionMap={selectionMap.current}
      swApps={groupedSoftwareApps[activeTabKey] ?? []}
      updateSelectedSoftwareAppVersion={updateSelectedSoftwareAppVersion}
    />
  );
};
