/* eslint-disable */
import { ConfigProvider, Spin } from 'antd';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { lightTheme } from './themes/light';
import { darkTheme } from './themes/dark';
import { common, primitivesDark, primitivesLight } from './themes/common';
import { useStyles } from './useStyles';
import { AnimatedLoadingIcon } from '../shared/components/icons/AnimatedLoadingIcon';

// antd set global spin indicator
Spin.setDefaultIndicator(<Spin indicator={<AnimatedLoadingIcon />} />);

export enum THEMES {
  LIGHT = 'light',
  DARK = 'dark'
}

export const additionalStyles = {
  fontSizeMD: 13,
  topMenuTextColor: primitivesDark.grey.solid,
  headerIconButtonHoverBgColor: 'rgba(255, 255, 255, 0.15)',
  colorSecondaryDark: primitivesDark.teal[6],
  colorSecondaryBgDark: primitivesDark.teal[3],
  colorSecondaryLight: primitivesLight.teal[7],
  colorSecondaryBgLight: primitivesLight.teal[8],
  colorSecondary: primitivesLight.teal[8],
  pageHeaderDarkBg: primitivesDark.neutral[3],
  pageHeaderLightBg: primitivesLight.violet[10],
  dropdownDangerTextColor: primitivesLight.grey.solidReversed,
  primitivesLight,
  primitivesDark,
  colorTertiaryFocus: 'rgba(255, 255, 255, 0.15)'
} as const;

const getCurrentTheme = () => {
  return (localStorage.getItem('pacts-theme') || THEMES.LIGHT) as THEMES;
};

export const useTheme: () => [THEMES, (theme: THEMES) => void] = () => {
  const qc = useQueryClient();
  const chosen = useQuery(
    ['theme'],
    () => {
      return getCurrentTheme();
    },
    {
      staleTime: Infinity,
      refetchInterval: Infinity,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      initialData: getCurrentTheme()
    }
  );

  const setChosen = (chosenTheme: THEMES) => {
    localStorage.setItem('pacts-theme', chosenTheme);
    qc.setQueryData(['theme'], chosenTheme);
  };
  return [chosen.data!, setChosen];
};

// Set basic dom styles here
const CommonStyleApplication = (props: { children: React.ReactNode }) => {
  const styles = useStyles();
  const [selectedTheme] = useTheme();

  const themeStyles = {
    ...styles,
    ...additionalStyles,
    selectedTheme
  } as const;

  document.body.style.backgroundColor = styles.colorBgLayout;
  return <StyledThemeProvider theme={themeStyles}>{props.children}</StyledThemeProvider>;
};

export const ThemeProvider = (props: { children: React.ReactNode }) => {
  const [chosen] = useTheme();
  const alg = chosen === THEMES.LIGHT ? lightTheme() : darkTheme();
  const token = useStyles();
  const antTheme = {
    algorithm: alg.algorithm,
    token: { ...common, ...alg.colors },
    components: { ...alg.tokens(token) }
  };

  return (
    <ConfigProvider theme={antTheme}>
      <CommonStyleApplication>{props.children}</CommonStyleApplication>
    </ConfigProvider>
  );
};
