import { useMutation, useQueryClient } from '@tanstack/react-query';
import { presentSuccess } from '../../../api/shared/successPresenter';
import { useEngineeringBackend } from '../../../api/engineering/hooks/useEngineeringBackend';
import { Project } from '../../../api/engineering/domain/types';
import { presentAppError } from '../../../api/shared/errorPresenter';

export function useDeleteProject() {
  const { backend } = useEngineeringBackend();
  const queryClient = useQueryClient();
  return useMutation<any, any, [Project]>(async ([project]) => {
    // to delete a project, the bundles have to be deleted first
    // since bundles are not modelled as distinct entities on the UI
    // we implicitly delete them while deleting the project
    const bundles = await backend.queryBundles(project.idProject.toString());

    // check if bundles have configurations and if so, fail the process
    const configs = (
      await Promise.all(bundles.map((bundle) => backend.queryBundleConfigurations(project.idProject.toString(), bundle.idBundle.toString())))
    ).flat();

    // throw an error if any bundle contains configs
    if (configs.length > 0) {
      const existingRefferenceError = new Error('Configurations have to be deleted first.');
      presentAppError(existingRefferenceError);

      throw existingRefferenceError;
    }

    await Promise.all(bundles.map((bundle) => backend.deleteBundle(project.idProject, bundle.idBundle)));

    await backend.deleteProject(project.idProject.toString());
    presentSuccess('Successfully deleted Project');

    await queryClient.invalidateQueries(['projects']);
  });
}
