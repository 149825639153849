import { useContext, useMemo } from 'react';
import { Menu, Tooltip } from 'antd';
import { UserSwitchOutlined, DiffOutlined, NotificationOutlined, ApartmentOutlined } from '@ant-design/icons';
import { Location, NavLink, useLocation } from 'react-router-dom';
import { PermissionsCalculator, PacTSPermissions } from '@pacts/permissions-lib';
import { PacTSContext } from '../../../state/store';
import { useProjects, useProjectTypes } from '../../projects/hooks';
import { usePermissions } from '../../session/hooks/usePermissions';
import { useSession } from '../../../contexts/session/hooks/useSession';
import styled from 'styled-components';

const StyledMenu = styled(Menu)`
  .ant-menu-item,
  .ant-menu-submenu-horizontal .ant-menu-submenu-title {
    color: ${({ theme }) => theme.topMenuTextColor};
  }

  .ant-menu-item {
    min-width: 170px;
    text-align: center;
  }

  .ant-menu-item * {
    color: ${({ theme }) => theme.topMenuTextColor};
  }
`;

const { SubMenu: AntSubMenu } = StyledMenu;

const SubMenu = styled(AntSubMenu)`
  &.ant-menu-submenu-vertical.ant-menu-submenu-active .ant-menu-submenu-title {
    color: white !important;
    background-color: ${({ theme }) => theme.Menu?.horizontalItemSelectedBg} !important;
  }

  &.ant-menu-submenu-vertical.ant-menu-submenu-selected .ant-menu-submenu-title {
    background-color: ${({ theme }) => theme.Menu?.horizontalItemSelectedBg} !important;
  }

  &.ant-menu-submenu-horizontal {
    min-width: 170px;
    text-align: center;
  }
`;

function getSelectedKeys(location: Location) {
  // In order for the menu to show correct active state
  // the selectedKeys have to be modified to match the route hierarchy
  const subdirectories = location.pathname.split('/').filter((path) => path.length > 0);
  const isSubdirectorySelected = subdirectories.length > 1;

  if (location.search.includes('type=')) {
    return [new URLSearchParams(location.search).get('type') ?? '1'];
  } else if (isSubdirectorySelected) {
    const rootDirectory = `/${subdirectories.find((path) => path.length > 0)}`;
    return [rootDirectory];
  }

  return [location.pathname];
}

export const TopMenu = () => {
  const location = useLocation();
  const permissions = usePermissions();
  const projects = useProjects();
  const projectTypes = useProjectTypes();
  const [state] = useContext(PacTSContext);
  const session = useSession(state);
  const projectPermissions = useMemo(() => {
    const projPerms = new Map<string, PacTSPermissions>();
    if (projects.data && state.token) {
      projects.data?.forEach((p) => {
        projPerms.set(
          p.idProject!.toString(),
          PermissionsCalculator.calculate(session.userInfo?.permissions ?? {}, { projectId: p.idProject?.toString() }).calculatedPermission
        );
      });
    }
    return projPerms;
  }, [projects.data, state.token, session.userInfo?.permissions]);

  const canAdministrateProjects = Array.from(projectPermissions.values()).some((p) => p.engineeringSvc$updateProjectMember);
  const canAdministrateUsers = permissions.userSvc$getUsers && permissions.userSvc$getRolesOfUser && permissions.userSvc$updateRolesOfUser;
  const hasAdminFeatures = canAdministrateProjects || canAdministrateUsers;
  const selectedKeys = getSelectedKeys(location);
  const referenceProjectTypes = projectTypes.data?.filter((type) => type.isReferenceProject) || [];
  const isReferenceProjectTypes = !!referenceProjectTypes.length && permissions.engineeringSvc$getProjects;

  const projectShortcuts = useMemo(() => {
    if (projectTypes.data && projects.data) {
      return projectTypes.data
        .filter((type) => permissions.engineeringSvc$getProjects && !type.isReferenceProject)
        .map((pt) => {
          const tooltipTitle = `All available ${pt.name} with releases, deployments, analytics, reports and P.Apps.`;

          return (
            <StyledMenu.Item key={pt.idProjectType.toString()}>
              <Tooltip mouseEnterDelay={1} title={tooltipTitle}>
                <NavLink style={{ height: '100%', display: 'block' }} to={`/projects?type=${pt.idProjectType}`} className="nav-text">
                  {pt.name}
                </NavLink>
              </Tooltip>
            </StyledMenu.Item>
          );
        });
    }
    return [];
  }, [permissions.engineeringSvc$getProjects, projectTypes.data, projects.data]);

  return (
    <>
      {session.state === 'loggedIn' && (
        <StyledMenu mode="horizontal" selectedKeys={selectedKeys} triggerSubMenuAction="hover">
          <StyledMenu.Item key="/" tabIndex={0}>
            <NavLink to="/" className="nav-text">
              Home
            </NavLink>
          </StyledMenu.Item>
          {projectShortcuts}
          <SubMenu popupOffset={[-18, 0]} key="platforms" title="Platforms">
            {permissions.engineeringSvc$getCommonSoftwareApps && (
              <StyledMenu.Item key="/apps" tabIndex={0}>
                <NavLink style={{ height: '100%', display: 'block' }} to="/apps" className="nav-text">
                  Software Platform
                </NavLink>
              </StyledMenu.Item>
            )}

            {permissions.engineeringSvc$getTools && (
              <StyledMenu.Item key="/tools" tabIndex={0}>
                <NavLink style={{ height: '100%', display: 'block' }} to="/tools" className="nav-text">
                  Engineering Platform
                </NavLink>
              </StyledMenu.Item>
            )}

            {isReferenceProjectTypes && (
              <>
                {referenceProjectTypes.map((refType) => (
                  <StyledMenu.Item key="4" tabIndex={0}>
                    <NavLink style={{ height: '100%', display: 'block' }} to={`/projects?type=${refType.idProjectType}`} className="nav-text">
                      {refType.name}
                    </NavLink>
                  </StyledMenu.Item>
                ))}
              </>
            )}
          </SubMenu>

          {hasAdminFeatures && (
            <SubMenu key="pacts-administration" title="PacTS Administration">
              {canAdministrateProjects && (
                <StyledMenu.Item key="/project-member-administration" icon={<UserSwitchOutlined />} tabIndex={0}>
                  <NavLink to="/project-member-administration" className="nav-text">
                    Project Member Administration
                  </NavLink>
                </StyledMenu.Item>
              )}
              {/* TODO: refine to usecase */}
              {canAdministrateUsers && (
                <StyledMenu.Item key="/user-administration" icon={<UserSwitchOutlined />} tabIndex={0}>
                  <NavLink to="/user-administration" className="nav-text">
                    User Administration
                  </NavLink>
                </StyledMenu.Item>
              )}
              {/* TODO: refine */}
              {permissions.all$unrestrictedAdministration && (
                <StyledMenu.Item key="/entity-administration" icon={<DiffOutlined />} tabIndex={0}>
                  <NavLink to="/entity-administration" className="nav-text">
                    Entity Administration
                  </NavLink>
                </StyledMenu.Item>
              )}
              {permissions.all$unrestrictedAdministration && (
                <StyledMenu.Item key="/session-administration" icon={<DiffOutlined />} tabIndex={0}>
                  <NavLink to="/session-administration" className="nav-text">
                    Session Administration
                  </NavLink>
                </StyledMenu.Item>
              )}
              {permissions.pactsFormationSvc$unrestrictedAdministration && (
                <StyledMenu.Item key="/pactsformation" icon={<ApartmentOutlined />} tabIndex={0}>
                  <NavLink to="/pactsformation" className="nav-text">
                    PacTS Formation
                  </NavLink>
                </StyledMenu.Item>
              )}
              {permissions.all$unrestrictedAdministration && (
                <StyledMenu.Item key="/notification-administration" icon={<NotificationOutlined />} tabIndex={0}>
                  <NavLink to="/notification-administration" className="nav-text">
                    Notification Dashboard
                  </NavLink>
                </StyledMenu.Item>
              )}
            </SubMenu>
          )}
        </StyledMenu>
      )}
    </>
  );
};
