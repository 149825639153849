import { Card, Flex, Typography } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { MoreOutlined } from '@ant-design/icons';
import { UpdateBundleConfiguration, useBundleConfigurationVersions, useDeleteBundleConfiguration } from '../../../contexts/bundles';
import { usePermissions } from '../../session/hooks/usePermissions';
import { ConfirmationButton } from '../../shared/components/ConfirmationButton';
import { useNavigate } from 'react-router-dom';
import { formatDate } from '../../../contexts/shared/components/formatDate';
import { StopEventPropagationWrapper } from '../../../contexts/shared/components/StopEventPropagationWrapper';
import { ExpandableMenu } from '../../../contexts/shared/components/ExpandableMenu';

const FullWidthCard = styled(Card)`
  width: 340px;
`;

const DescriptionItemWrapper = styled.div`
  max-width: 50%;
`;

const DescriptionItem = (diProps: { text: string | undefined }) => (
  <DescriptionItemWrapper>
    <Typography.Text ellipsis={{ tooltip: diProps.text || '-' }}>{diProps.text || '-'}</Typography.Text>
  </DescriptionItemWrapper>
);

interface AppConfigurationCardProps {
  name: string;
  isLoading?: boolean;
  onConfirmDelete?: () => void;
  id: number;
  lastVersionId: number | undefined;
  description: string;
  projectId: string;
  bundleId: string;
  bundleName: string;
}

const ConfigurationCard: React.FC<AppConfigurationCardProps> = ({
  isLoading = false,
  id,
  lastVersionId,
  description,
  name,
  projectId,
  bundleId,
  bundleName
}) => {
  const permissions = usePermissions({ projectId });
  const navigate = useNavigate();
  const versions = useBundleConfigurationVersions(projectId, bundleId, String(id));
  const deleteBundleConfig = useDeleteBundleConfiguration();
  
  const lastVersion = versions.data?.find((release) => Number(release.idBundleRelease) === Number(lastVersionId));

  const lastReleaseVersion = lastVersion ? lastVersion.version : '-';
  const lastReleaseDate = lastVersion ? lastVersion.createdAt : '';

  const handleCardClick = () => {
    navigate(`/projects/${projectId}/${bundleName}/configurations/${id}`);
  };

  const flexItemOptions = {
    justify: 'flex-start',
    gap: 4
  };

  return (
    <FullWidthCard
      id={`app-conf-card-${id}`}
      size="small"
      loading={isLoading}
      hoverable
      onClick={handleCardClick}
      title={<Typography.Text strong>{name}</Typography.Text>}
      extra={
        <StopEventPropagationWrapper>
          <ExpandableMenu textType id={`update-app-config-${id}`} icon={<MoreOutlined />} disabled={isLoading}>
            {permissions.engineeringSvc$updateProjectBundleConfiguration && (
              <UpdateBundleConfiguration title="Edit" projectId={projectId.toString()} bundleId={bundleId} configId={id.toString()} />
            )}

            {permissions.engineeringSvc$deleteProjectBundleConfiguration && (
              <ConfirmationButton
                title={`Deleting ${name}`}
                caption="Delete"
                danger
                description="All deployment plans associated with this configuration will also be deleted."
                paragraphDescription={
                  <Typography.Paragraph>
                    Are you sure you want to delete <Typography.Text strong>{name}</Typography.Text> configuration?
                  </Typography.Paragraph>
                }
                onOk={() => {
                  deleteBundleConfig.mutate([projectId.toString(), bundleId, String(id)]);
                }}
              />
            )}
          </ExpandableMenu>
        </StopEventPropagationWrapper>
      }
    >
      <Flex vertical gap={'0.6em'}>
        <Flex {...flexItemOptions}>
          <DescriptionItem text="Last version:" />
          <Typography.Text strong ellipsis={{ tooltip: { title: lastReleaseVersion, placement: 'bottom' } }}>
            {lastReleaseVersion}
          </Typography.Text>
        </Flex>
        <Flex {...flexItemOptions}>
          <DescriptionItem text="Release date:" />
          <Typography.Text strong ellipsis={{ tooltip: { title: lastReleaseDate, placement: 'bottom' } }}>
            {lastReleaseDate ? formatDate(new Date(lastReleaseDate)) : '-'}
          </Typography.Text>
        </Flex>
        <Flex {...flexItemOptions}>
          <DescriptionItem text="Config. description:" />
          <Typography.Text ellipsis={{ tooltip: { title: description, placement: 'bottom' } }}>{description}</Typography.Text>
        </Flex>
      </Flex>
    </FullWidthCard>
  );
};

export default ConfigurationCard;
