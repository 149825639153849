import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useEngineeringBackend } from '../../../api/engineering/hooks/useEngineeringBackend';
import { Project } from '../../../api/engineering/domain/types';
import { presentSuccess } from '../../../api/shared/successPresenter';

export function useDeleteProjectAccessRequest() {
  const { backend } = useEngineeringBackend();
  const queryClient = useQueryClient();
  return useMutation<void, any, [Project, number]>(([project, memberId]) => {
    return new Promise<void>((resolve, reject) => {
      backend
        .deleteProjectAccessRequest(project.idProject, memberId)
        .then((ret) => {
          presentSuccess(`Successfully declined Project Member`);
          queryClient.invalidateQueries(['projectAccessRequest', project.idProject]);
          queryClient.invalidateQueries(['projectAccessRequests', project.idProject]);
          return resolve(ret);
        })
        .catch(reject);
    });
  });
}
