import { ContainerOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import { useState } from 'react';
import { CommonSoftwareAppVersionContentProvider } from './CommonSoftwareAppVersionContentProvider';
import { ProjectSoftwareAppVersionContentProvider } from './ProjectSoftwareAppVersionContentProvider';
import { ShiftedDrawer } from '../../shared/components/ShiftedDrawer';
import { SoftwareComponent } from '../../../api';

export const SoftwareAppVersionPreview = (props: { projectId: string; component: SoftwareComponent | null; disabled?: boolean }) => {
  const [previewVisible, setPreviewVisible] = useState(false);
  return (
    <>
      <ShiftedDrawer
        open={previewVisible && props.component != null}
        onClose={() => {
          setPreviewVisible(false);
        }}
        title={`App Info: ${props.component?.name} ${props.component?.version}`}
      >
        {props.component?.scope === 'project' && <ProjectSoftwareAppVersionContentProvider component={props.component} projectId={props.projectId} />}

        {props.component?.scope === 'common' && <CommonSoftwareAppVersionContentProvider component={props.component} />}
      </ShiftedDrawer>

      <Tooltip title="App Info">
        <Button 
          icon={<ContainerOutlined />} 
          disabled={props.disabled ?? false} 
          type="text"
          onClick={() => setPreviewVisible(true)}
        />
      </Tooltip>
    </>
  );
};
