import { Space } from 'antd';

import { SubscriptionPopup } from '../../notifications/components/SubscriptionPopup';
import { usePermissions } from '../../session/hooks/usePermissions';
import { ExpandableMenu } from '../../shared/components/ExpandableMenu';
import { useDeleteProject } from '../hooks/useDeleteProject';
import { UpdateProject } from './UpdateProject';

import { ConfirmationButton } from '../../shared/components/ConfirmationButton';
import { ProjectJoinButton } from './ProjectJoinButton';
import { AddProject } from './AddProject';

import type { Project } from '../../../api/engineering/domain/types';


export type ProjectActionsProps = {
  project: Project;
};

export const ProjectActions = (props: ProjectActionsProps) => {
  const deleteProject = useDeleteProject();
  const permissions = usePermissions({ projectId: props.project.idProject.toString() });

  const topics = [
    { key: `projects/${props.project.idProject}/bundles/*/configurations/*/releases/*/*`, title: 'Releases' },
    { key: `projects/${props.project.idProject}/environments/*/deploymentplans/*/created`, title: 'Deployments' }
  ];

  if (permissions.engineeringSvc$updateProjectMember) {
    topics.push({ key: `projects/${props.project.idProject}/members/*/accessrequest/created`, title: 'Access Requests' });
  }

  if (permissions.all$unrestrictedAdministration) {
    topics.push({ key: 'projects/*/members/*/accessrequest/created', title: 'All Access Requests (Admin)' });
  }

  return (
    <Space style={{ float: 'right' }}>
      <ProjectJoinButton project={props.project} />
      <AddProject key="addProject" />
      <SubscriptionPopup key="deployments" topics={topics} />
      <ExpandableMenu id={`project-actions-${props.project.idProject}`}>
        {permissions.engineeringSvc$updateProject && <UpdateProject project={{ ...props.project, projectPhase: props.project.phase }} />}
        {permissions.engineeringSvc$deleteProject && (
          <ConfirmationButton
            danger
            caption="Delete"
            description="All deployment plans associated with this project will also be deleted."
            onOk={() => {
              deleteProject.mutate([props.project]);
            }}
          />
        )}
      </ExpandableMenu>
    </Space>
  );
};
