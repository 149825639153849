import { Row, Col, Card, Layout, Typography } from 'antd';
import { useState } from 'react';
import { EngineeringToolSelection } from '../../../domain/engineeringToolsSelection';
import { usePermissions } from '../../session/hooks/usePermissions';
import { AddEngineeringTool } from './AddEngineeringTool';
import EngineeringToolsList from './EngineeringToolsList';
import { PageHeader } from '@ant-design/pro-layout';
import BreadcrumbsContainer from '../../../contexts/projects/components/BreadcrumbsContainer';
import styled from 'styled-components';

const StyledPageHeader = styled(PageHeader)`
  position: sticky !important;
  top: 64px !important;
  z-index: 4 !important;
  background-color: ${({ theme }) => theme.colorBgLayout} !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
`;

const StyledCard = styled(Card)`
  margin: 0 10px !important;
`;

const StyledTitle = styled(Typography.Title)`
  margin: 0;
  margin-bottom: 4px;
`;

export const EngineeringToolsView = () => {
  const [selectedTools, setSelectedTools] = useState([] as EngineeringToolSelection[]);
  const [initialSelection] = useState([] as EngineeringToolSelection[]);
  const breadcrumbItems = [{ title: 'Engineering platform' }];
  const permissions = usePermissions();

  return (
    <Layout>
      <BreadcrumbsContainer items={breadcrumbItems} />
      <StyledPageHeader
        title={<StyledTitle level={4}>Engineering Platform Tools Overview</StyledTitle>}
        extra={[permissions.engineeringSvc$addTool && <AddEngineeringTool key="addTool" />]}
      />
      <StyledCard>
        <Row>
          <Col span={24}>
            <EngineeringToolsList selected={selectedTools} onSelect={setSelectedTools} initiallySelected={initialSelection} hideCheckboxes />
          </Col>
        </Row>
      </StyledCard>
    </Layout>
  );
};
