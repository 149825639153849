import styled from 'styled-components';

export const SegmentedWrapper = styled.div<{ hasTopOffset?: boolean }>`
  position: sticky;
  top: 0;
  z-index: 4;
  width: 100%;
  background-color: ${({ theme }) => theme.colorBgElevated};
  ${({ hasTopOffset = false }) =>
    hasTopOffset
      ? `
      top: 118px;
      padding-top: 20px;
  `
      : ''}
`;
