import React from 'react';
import { useBundles } from '../../bundles/hooks/useBundles';
import { AddBundleConfiguration, useBundleConfigurations } from '../../../contexts/bundles';
import ProjectContentWrapper from '../../../contexts/projects/components/ProjectContentWrapper';
import { usePermissions } from '../../session/hooks/usePermissions';
import { Col, Row } from 'antd';
import { useProject } from '../../projects/hooks/useProject';
import ConfigurationCard from './ConfigurationCard';
import { useParams } from 'react-router-dom';
import { useSearchParameter } from '../../../contexts/navigation/hooks';

type ProjectSoftwareContainerParams = {
  projectId: string;
  bundleName: 'apps' | 'tools';
};

const ProjectSoftwareContainer = () => {
  const { projectId, bundleName } = useParams() as ProjectSoftwareContainerParams;
  const [projectType] = useSearchParameter('type');
  const bundleLabel = bundleName === 'apps' ? 'Applications' : 'Tools';
  const permissions = usePermissions({ projectId: projectId });

  const bundles = useBundles(projectId);
  const usedBundle = bundles.data?.find((bundle) => bundle.name.includes(bundleLabel));
  const bundleId = usedBundle?.idBundle?.toString() || '';
  const data = useBundleConfigurations(projectId, bundleId);
  const project = useProject(projectId.toString());

  const breadcrumbItems = [{ title: project.data?.name, url: `/projects?active=${projectId}&type=${projectType}` }, { title: bundleLabel }];

  return (
    <ProjectContentWrapper
      title={`${bundleLabel} configurations`}
      breadcrumbItems={breadcrumbItems}
      extra={
        permissions.engineeringSvc$addProjectBundleConfiguration ? <AddBundleConfiguration type="button" bundleId={bundleId} projectId={projectId} /> : null
      }
      isLoading={data.isLoading}
    >
      <Row gutter={[16, 16]}>
        {data.data?.map((bundleConfig) => (
          <Col key={bundleConfig.idBundleConfiguration}>
            <ConfigurationCard
              id={bundleConfig.idBundleConfiguration}
              bundleId={bundleId}
              bundleName={bundleName}
              name={bundleConfig.name}
              lastVersionId={bundleConfig.latestBundleReleaseId}
              description={bundleConfig.description}
              isLoading={false}
              projectId={projectId}
            />
          </Col>
        ))}
      </Row>
    </ProjectContentWrapper>
  );
};

export default ProjectSoftwareContainer;
