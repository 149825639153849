/* eslint-disable react/jsx-no-comment-textnodes */
import { Layout, Row, Tag, Typography } from 'antd';
import { useState } from 'react';
import { useEnvironment } from '../../navigation/hooks/useEnvironment';
import { Emoji } from '../../shared/components/Emoji';
import styled from 'styled-components';
import { Redirects } from '@pacts/utils-redirects';

const { Footer } = Layout;

const StyledLink = styled(Typography.Link)`
  font-size: inherit;
  color: inherit;
`;

const StyledFooter = styled(Footer)<{ paddingBottom: number }>`
  text-align: 'center';
  padding: 0;
  background-color: transparent;
  padding-bottom: ${(args) => `${args.paddingBottom}px`};
`;

const StyledFooterContentRow = styled(Row)`
  margin-bottom: 8px;
  margin-top: 8px;
  padding-left: 8px;
  padding-right: 8px;
`;

const StyledFooterText = styled(Typography.Text)`
  // slightly smaller than small font size
  // TODO: reevaluate after branding
  font-size: ${(args) => `${args.theme.fontSizeSM - 2}px`};
`;

const NonProductionWarningTag = styled(Tag)`
  position: fixed;
  bottom: 0;
  left: 0;
  line-height: 2;
  width: 100%;
  text-align: center;
  // slightly smaller than normal font size
  // TODO: reevaluate after branding
  font-size: ${(args) => `${args.theme.fontSize - 1}px`};
  z-index: 10;
`;

export const PacTSFooter = ({ hideFooter = false }) => {
  const pactsEnvironment = useEnvironment();
  const envText = pactsEnvironment.toUpperCase();
  const hasEnvText = envText !== '';
  const [tagOpen, setTagOpen] = useState(hasEnvText);
  const footerBottomPadding = tagOpen ? 28 : 0;
  return (
    <>
      {hasEnvText && (
        <NonProductionWarningTag
          closable
          onClose={() => {
            setTagOpen(false);
          }}
          color="warning"
          style={{ position: 'fixed', bottom: 0, left: 0, lineHeight: 2, width: '100%', textAlign: 'center', fontSize: '14px', zIndex: 10 }}
        >
          {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
          This is a non-production system. Your changes will not be saved! Please go to{' '}
          <Typography.Link href="https://pacts.cloud" target="_blank" rel="noreferrer">
            pacts.cloud
            {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
          </Typography.Link>{' '}
          for the production system.
        </NonProductionWarningTag>
      )}
      {!hideFooter && (
        <StyledFooter paddingBottom={footerBottomPadding}>
          <StyledFooterContentRow justify="center">
            <StyledFooterText>
              Made with <Emoji symbol="💜" label="Heart" /> by Engineering Platform Team (SE GT GS CP ENP)
            </StyledFooterText>

            <StyledFooterText>
              Siemens Energy is a trademark licensed by Siemens AG ©{' '}
              <StyledLink target="_blank" href={Redirects.SE_IMPRINT}>
                Siemens Energy 2024
              </StyledLink>{' '}
              ·{' '}
              <StyledLink target="_blank" href={Redirects.SE_PRIVACY_NOTICE}>
                Privacy Notice
              </StyledLink>{' '}
              ·{' '}
              <StyledLink target="_blank" href={Redirects.SE_TERMS_OF_USE}>
                Terms of Use
              </StyledLink>{' '}
              ·{' '}
              <StyledLink target="_blank" href={Redirects.SIGNING_KEY}>
                Public Key
              </StyledLink>
            </StyledFooterText>
          </StyledFooterContentRow>
        </StyledFooter>
      )}
    </>
  );
};
