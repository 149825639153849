import { useSearchParams } from 'react-router-dom';
import { Descriptions, Space } from 'antd';
import { usePermissions } from '../../session/hooks/usePermissions';
import { formatDateTime } from '../../shared/components/formatDate';
import { useDeleteEngineeringTool } from '../hooks/useDeleteEngineeringTool';
import { UpdateEngineeringTool } from './UpdateEngineeringTool';
import { MarkdownRenderer } from '../../shared/components/MarkdownRenderer';
import { ConfirmationButton } from '../../shared/components/ConfirmationButton';
import { useInAppNavigate } from '../../navigation/hooks/useInAppNavigate';
import { ShiftedDrawer } from '../../shared/components/ShiftedDrawer';

import type { Tool } from '../../../api/engineering/domain/types';

type Props = {
  tool: Tool;
  onClose: () => void;
  open: boolean;
};

export const EngineeringToolDetailsDrawer = (props: Props) => {
  const { tool, onClose, open } = props;
  const permissions = usePermissions();
  const navigate = useInAppNavigate();
  const [searchParam] = useSearchParams();
  const projectId = searchParam.get('projectId') || '';
  const deleteEngineeringTool = useDeleteEngineeringTool();

  return (
    <ShiftedDrawer
      onClose={onClose}
      open={open}
      title={`Details: ${tool.name}`}
      extra={
        <Space>
          <UpdateEngineeringTool key="update" tool={tool} detailView />

          {permissions.engineeringSvc$deleteTool && (
            <ConfirmationButton
              danger
              buttonType="regular"
              regularButtonType="default"
              key="delete"
              onOk={() => {
                deleteEngineeringTool
                  .mutateAsync([tool])
                  .then(() => (projectId ? navigate(`/projects/${projectId}/tools`, { replace: true }) : navigate('/tools', { replace: true })))
                  .catch(() => {});
              }}
            >
              Delete
            </ConfirmationButton>
          )}
        </Space>
      }
    >
      <Descriptions bordered column={1}>
        <Descriptions.Item label="Description">
          <MarkdownRenderer centered>{`${tool.description}`}</MarkdownRenderer>
        </Descriptions.Item>

        <Descriptions.Item label="Documentation">
          <MarkdownRenderer centered>{`${tool.documentationLink}`}</MarkdownRenderer>
        </Descriptions.Item>

        <Descriptions.Item label="Created">
          {tool.createdAt ? formatDateTime(new Date(tool.createdAt)) : '-'}
          {tool.createdBy ? ` by ${tool.createdBy}` : ''}
        </Descriptions.Item>

        <Descriptions.Item label="Updated">
          {tool.updatedAt ? formatDateTime(new Date(tool.updatedAt)) : '-'}
          {tool.updatedBy ? ` by ${tool.updatedBy}` : ''}
        </Descriptions.Item>

        <Descriptions.Item label={tool.category?.length === 1 ? 'Category' : 'Categories'} contentStyle={{ alignContent: 'center', justifyContent: 'center' }}>
          {tool.category?.map((cat) => cat.name).join('; ')}
        </Descriptions.Item>
      </Descriptions>
    </ShiftedDrawer>
  );
};
