import { SearchOutlined } from '@ant-design/icons';
import { Card, Divider, Input, Menu, Row, Select, Space, Typography } from 'antd';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { uniqBy } from 'lodash';

import { useProjects, useProjectSolutionTypes, useProjectTypes } from '../hooks';
import { allSolutionTypeKey, useSelectedProjectSolutionType } from '../hooks/useSelectedProjectSolutionType';
import { useLocation } from 'react-router-dom';
import { AnimatedLoadingIcon } from '../../shared/components/icons/AnimatedLoadingIcon';

import type { Project, ProjectSolutionType } from '../../../api/engineering/domain/types';
import { THEMES } from '../../theme/ThemeProvider';
import { useSearchParameter } from '../../../contexts/navigation/hooks';
import { useProjectsMenuItems } from '../../navigation/hooks/useProjectsMenuItems';

interface ProjectsMenuProps {
  active: string | null | undefined;
  setActive: (value: string) => void;
  selectableProjects: Project[];
}

export function extractProjectId(url: string): string | null {
  const regex = /\/projects?\/(\d+)\//;
  const match = url.match(regex);
  return match ? match[1] : null;
}

export const StyledMenu = styled(Menu)`
  max-height: calc(100% - 110px);
  min-width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  background: transparent;

  .ant-menu-item {
    border-radius: 0;
    margin: 0;
    padding: 0;
    width: 100%;
  }

  .ant-menu-item-selected .ant-btn-icon {
    color: white;
  }

  & .ant-menu-item-selected {
    background: ${({ theme }) => theme.colorSecondaryBgLight};
  }

  .ant-menu-submenu-title {
    margin: 0 !important;
    border-radius: 0 !important;
    width: 100% !important;
  }

  &.ant-menu-light .ant-menu-submenu-selected > .ant-menu-submenu-title,
  &.ant-menu-light > .ant-menu .ant-menu-submenu-selected > .ant-menu-submenu-title {
    color: ${({ theme }) => theme.colorPrimary};
    font-weight: 700;

    ${({ theme }) =>
      theme.selectedTheme === THEMES.DARK
        ? `
        color: ${theme.colorLink};
      `
        : ''}
  }

  .ant-menu-item:not(.ant-menu-item-selected) {
    color: ${({ theme }) => theme.colorText};
  }

  .ant-menu-submenu-active {
    .ant-btn-icon {
      color: ${({ theme }) => (theme.selectedTheme === THEMES.DARK ? theme.colorWhite : theme.colorPrimary)};
      display: block;
    }
  }

  .ant-menu-submenu-selected {
    .ant-btn-icon {
      color: ${({ theme }) => (theme.selectedTheme === THEMES.DARK ? theme.colorLink : theme.colorPrimary)};
      display: block;
    }
  }

  .ant-btn-icon {
    display: none;
    color: ${({ theme }) => theme.colorText};
  }

  .ant-btn-icon:has(.pinned-icon) {
    display: block;
  }
`;

export const FlexRow = styled(Row)`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const archivedSolutionTypeKey = 'archived';
const inputMargin = 12;

export const ProjectsMenu: React.FC<ProjectsMenuProps> = (props) => {
  const { active } = props;
  const [projectType] = useSearchParameter('type');
  const shouldRestrictKeysEffect = useRef<boolean>(false);
  const location = useLocation();
  const projects = useProjects();
  const types = useProjectTypes();
  const allSolutionTypes = useProjectSolutionTypes();
  const [openKeys, setOpenKeys] = useState<string[]>([]);
  const [search, setSearch] = useState('');
  const [solutionType, setSolutionType] = useSelectedProjectSolutionType();
  const loading = projects.isLoading || types.isLoading || allSolutionTypes.isLoading;
  const activeProject = projects.data?.find((p) => p.idProject.toString() === active);
  const items = useProjectsMenuItems(props.selectableProjects, solutionType, projectType, search, active, props.setActive);

  // in case a user is not entitled to load all project types
  const availableSolutionTypes = useMemo(() => {
    return uniqBy([...(allSolutionTypes.data ?? []), activeProject?.projectSolutionType].filter(Boolean), (s) => s!.id) as ProjectSolutionType[];
  }, [activeProject, allSolutionTypes.data]);

  // Reset solution type storage if invalid
  useEffect(() => {
    const isNonDefaultType = solutionType !== allSolutionTypeKey;
    const isNonArchivedType = solutionType !== archivedSolutionTypeKey;
    const idIsExisting = availableSolutionTypes?.find((st) => st.id.toString() === solutionType) && true;
    const hasData = availableSolutionTypes && true;
    if (isNonDefaultType && isNonArchivedType && hasData && !idIsExisting) {
      setSolutionType(allSolutionTypeKey);
    }
  }, [solutionType, availableSolutionTypes, setSolutionType]);

  useEffect(() => {
    if (shouldRestrictKeysEffect.current) {
      return;
    }

    const activeProjectId = activeProject?.idProject;
    const projectId = extractProjectId(location.pathname) || (!!activeProjectId ? String(activeProjectId) : undefined);
    const parents = location.pathname
      .split('/')
      .filter(Boolean)
      .map((_, i, arr) => '/' + arr.slice(0, i + 1).join('/'));

    // In order for the keys to be opened automatically
    // on render we need to pass the keys, the default one is the url
    const newOpenKeys = [location.pathname];
    const isEnvironmentLink = location.pathname.includes('/deployments/');

    if (!!projectId) {
      newOpenKeys.push(...[projectId, `/${projectId}`]);
    }

    if (!!parents.length) {
      newOpenKeys.push(...parents);
    }

    if (isEnvironmentLink && !!projectId) {
      newOpenKeys.push(`/projects/${projectId}/deployments/environments`);
    }

    setOpenKeys(newOpenKeys);
  }, [location.pathname, projectType, activeProject]);

  const handleOpenChange = (keys: string[]) => {
    shouldRestrictKeysEffect.current = true;
    const isAllKeysRelated = keys.every((key) => key.includes(keys[0]));

    if (keys.length > 0) {
      if (isAllKeysRelated) {
        setOpenKeys(keys);
      } else {
        const projectId = extractProjectId(keys.at(-1) + '/');
        const parents = ((keys.at(-1) + '/') as string)
          .split('/')
          .filter(Boolean)
          .map((_, i, arr) => '/' + arr.slice(0, i + 1).join('/'));
        const newKeys = [...parents, keys.at(-1) as string];

        if (projectId) {
          newKeys.push(projectId);
        }
        setOpenKeys(newKeys);
      }
    } else {
      setOpenKeys([]);
    }

    setTimeout(() => {
      // We should restrain the useEffect to take effect when we click over an item
      // since it will trigger because of url change
      shouldRestrictKeysEffect.current = false;
    }, 1000);
  };

  const nonArchivedProjects = props.selectableProjects.filter((p) => !p.isArchived);
  const archivedProjects = props.selectableProjects.filter((p) => p.isArchived);

  const solutionTypeFilter =
    availableSolutionTypes?.map((st) => {
      const stProjects = nonArchivedProjects.filter((p) => p.projectSolutionType.id === st.id) || [];
      return (
        <Select.Option key={st.id?.toString()} title={st.name} value={st.id!.toString()}>
          {`${st.name} (${stProjects.length})`}
        </Select.Option>
      );
    }) || [];

  solutionTypeFilter.push(
    <Select.Option key={archivedSolutionTypeKey} title={'Archived'} value={archivedSolutionTypeKey}>
      {`Archived (${archivedProjects.length})`}
    </Select.Option>
  );

  solutionTypeFilter.unshift(
    <Select.Option key={allSolutionTypeKey} value={allSolutionTypeKey}>
      All project types ({nonArchivedProjects.length})
    </Select.Option>
  );

  const menu = (
    <Card style={{ width: '100%', height: '100%', margin: 0, padding: 0, border: 'none' }} styles={{ body: { margin: 0, padding: 0, display: 'inline' } }}>
      <div style={{ width: '100%', display: 'inline' }}>
        <Space direction="vertical" style={{ height: '110px', width: '100%', marginTop: 0 }} size="small">
          <Select
            placeholder="Select Type"
            style={{ width: `calc(100% - ${2 * inputMargin}px)`, margin: 0, marginLeft: inputMargin, marginRight: inputMargin, marginTop: 10 }}
            value={solutionType}
            showSearch
            bordered={false}
            defaultActiveFirstOption
            onChange={(s) => {
              setSolutionType(s);
            }}
            filterOption={(inputValue, option) => {
              return !!option?.title?.toString().toLowerCase().includes(inputValue.toLowerCase());
            }}
          >
            {solutionTypeFilter}
          </Select>
          <Divider style={{ margin: 4, marginLeft: 0 }} />
          <div style={{ marginLeft: inputMargin, marginRight: inputMargin, marginTop: 0, marginBottom: 0 }}>
            <Input
              bordered={false}
              value={search}
              allowClear
              placeholder="Search Name"
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              suffix={
                <Typography.Text type="secondary">
                  <SearchOutlined />
                </Typography.Text>
              }
            />
          </div>
          <Divider style={{ margin: 4, marginLeft: 0 }} />
        </Space>

        <StyledMenu
          multiple={false}
          onOpenChange={handleOpenChange}
          openKeys={openKeys}
          selectedKeys={[String(active), location.pathname]}
          mode="inline"
          items={items}
        />
      </div>
    </Card>
  );

  return loading ? (
    <div style={{ marginTop: 32 }}>
      <AnimatedLoadingIcon style={{ marginLeft: 'calc(50% - 8px)' }} />
    </div>
  ) : (
    menu
  );
};
