import { useState, useEffect } from 'react';
import { Button, Form } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { SemanticVersion } from '../../../domain';
import { usePermissions } from '../../session/hooks/usePermissions';
import { useCreateProjectSoftwareAppVersion } from '../hooks/useCreateProjectSoftwareAppVersion';
import { SoftwareAppVersionForm } from './SoftwareAppVersionForm';
import { SoftwareApp, SoftwareAppVersionCreate } from '../../../api/engineering/domain/types';
import { ShiftedDrawer } from '../../shared/components/ShiftedDrawer';

export type CreateSoftwareAppVersionProps = {
  projectId: string;
  parentApp: SoftwareApp;
  existingSoftwareAppVersion?: SoftwareAppVersionCreate;
  detailView?: boolean;
};

export const AddProjectSoftwareAppVersion = (props: CreateSoftwareAppVersionProps) => {
  const permissions = usePermissions({ projectId: props.projectId, softwareAppId: props.parentApp.idSoftwareApp.toString() });
  const [modalVisible, setModalVisible] = useState(false);
  const createSoftwareAppVersion = useCreateProjectSoftwareAppVersion();
  const [form] = Form.useForm();

  let okHandler: () => any;
  let resetHandler: () => any;

  const getInitialSwAppVersion = () => {
    if (props.existingSoftwareAppVersion) {
      const next: SoftwareAppVersionCreate = { ...props.existingSoftwareAppVersion };
      next.releaseNotes = '';
      next.version = SemanticVersion.fromUnsafeString(props.existingSoftwareAppVersion.version).increment().toString();
      next.targets.forEach((t) => {
        t.downloadLink = '';
        t.sha256 = undefined;
        t.icsPortalComponentId = undefined;
      });
      return next;
    }
    return {
      idSoftwareAppVersion: -1,
      releaseNotes: '',
      version: '',
      targets: []
    } as SoftwareAppVersionCreate;
  };

  useEffect(() => {
    if (createSoftwareAppVersion.isSuccess) {
      setModalVisible(false);
      form.resetFields();
    }
  }, [createSoftwareAppVersion.isSuccess, form]);

  const handleCancel = () => {
    resetHandler();
    setModalVisible(false);
    form.resetFields();
  };

  return (
    <>
      {permissions.engineeringSvc$addProjectSoftwareAppVersion && (
        <Button
          icon={<PlusOutlined />}
          loading={createSoftwareAppVersion.isLoading}
          onClick={() => {
            setModalVisible(true);
          }}
          type="default"
          style={props.detailView ? { float: 'right', marginRight: '20px' } : {}}
        >
          Add Version
        </Button>
      )}

      <ShiftedDrawer
        title="Add Software App Version"
        destroyOnClose
        loading={createSoftwareAppVersion.isLoading}
        open={modalVisible}
        isFormDrawer
        onOk={() => {
          okHandler();
        }}
        onCancel={handleCancel}
      >
        <SoftwareAppVersionForm
          initial={getInitialSwAppVersion()}
          ok={(ok) => {
            okHandler = ok;
          }}
          reset={(cancel) => {
            resetHandler = cancel;
          }}
          form={form}
          parentApp={props.parentApp}
          onFinish={async (ps) => {
            return new Promise((resolve, reject) => {
              createSoftwareAppVersion
                .mutateAsync([props.projectId, props.parentApp.idSoftwareApp.toString(), ps])
                .then(() => {
                  setModalVisible(false);
                  resolve();
                })
                .catch(() => {
                  reject();
                });
            });
          }}
        />
      </ShiftedDrawer>
    </>
  );
};
