import React from 'react';
import { Form, FormInstance, Input } from 'antd';

import { ReleaseNotesValidator } from '../../../domain/validation/releaseNotesValidator';
import { VersionValidator } from '../../../domain/validation/versionValidator';
import { Sha256Validator } from '../../../domain/validation/sha256Validator';
import { RELEASE_NOTES_PLACEHOLDER } from '../../../constants/texts';

import { MarkdownFormItem } from '../../shared/components/MarkdownFormItem';
import { BlobUploadFormItem } from '../../shared/components/BlobUploadFormItem';
import { ChecksumHowToFormLabel } from '../../../contexts/shared/components/ChecksumHowToFormLabel';
import { ICSPortalComponentIdFormItem, ICS_PORTAL_ID_NAME_KEY } from '../../shared/components/Forms/ICSPortalComponentIdFormItem';

import type { SoftwareApp, SoftwareAppVersion, SoftwareAppVersionCreate, SoftwareAppVersionUpdate } from '../../../api/engineering/domain/types';

export const SoftwareAppVersionForm = (props: {
  initial: SoftwareAppVersionCreate | SoftwareAppVersionUpdate;
  parentApp: SoftwareApp;
  onFinish: (data: SoftwareAppVersion) => Promise<void>;
  ok: (ok: () => any) => any;
  reset: (cancel: () => any) => any;
  form?: FormInstance;
}) => {
  const [form] = Form.useForm<SoftwareAppVersion>(props.form);
  props.ok(form.submit);
  props.reset(form.resetFields);

  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 }
  };

  return (
    <Form
      {...layout}
      name="basic"
      initialValues={props.initial}
      form={form}
      onFinish={(value) => {
        props.onFinish(value);
      }}
      labelAlign="left"
    >
      <Form.Item label="Version" name="version" required rules={[VersionValidator.rule()]}>
        <Input />
      </Form.Item>
      <MarkdownFormItem
        rules={[ReleaseNotesValidator.rule()]}
        label="Release Notes"
        field={['releaseNotes']}
        placeholder={RELEASE_NOTES_PLACEHOLDER}
        shouldUpdate={(prevValues, currentValues) => prevValues.releaseNotes !== currentValues.releaseNotes}
      />
      <Form.List name="targets">
        {(fields) => {
          return (
            <div>
              {fields.map((field, index) => (
                <React.Fragment key={index}>
                  <BlobUploadFormItem
                    label={`Download Link ${form.getFieldValue(['targets', index, 'target', 'name'])}`}
                    field={['targets', index, 'downloadLink']}
                    relativeFieldInList={[field.key, 'downloadLink']}
                    rules={[{ required: true, message: 'Required' }]}
                    key={form.getFieldValue(['targets', index, 'target', 'name'])}
                    shouldUpdate={(prevValues, currentValues) => {
                      return JSON.stringify(prevValues.targets) !== JSON.stringify(currentValues.targets);
                    }}
                  />
                  <Form.Item
                    label={<ChecksumHowToFormLabel target={form.getFieldValue(['targets', index, 'target', 'name'])} />}
                    name={[index, 'sha256']}
                    required={false}
                    rules={[Sha256Validator.rule(() => form.getFieldValue(['targets', index, 'downloadLink']))]}
                  >
                    <Input />
                  </Form.Item>

                  <ICSPortalComponentIdFormItem target={form.getFieldValue(['targets', index, 'target', 'name'])} name={[field.key, ICS_PORTAL_ID_NAME_KEY]} />
                </React.Fragment>
              ))}
            </div>
          );
        }}
      </Form.List>
    </Form>
  );
};
