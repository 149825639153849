import { Dropdown, Tooltip, Button } from 'antd';
import { UserOutlined, BulbFilled, LockOutlined, LogoutOutlined } from '@ant-design/icons';
import styled from 'styled-components';

import { useCurrentUser } from '../../session/hooks/useCurrentUser';
import { useLogout } from '../../session/hooks/useLogout';
import { ExternalTokenDialog } from '../../../contexts/token/ExternalTokenDialog';
import { useTheme, THEMES } from '../../theme/ThemeProvider';

import type { MenuProps } from 'antd';
import { useState } from 'react';

const UserMenuIcon = styled(UserOutlined)`
  color: white;
`;

const StyledButton = styled(Button)`
  &:hover {
    background: ${({ theme }) => theme.headerIconButtonHoverBgColor} !important;
  }
`;

export const AccountDropdown = () => {
  const currentUser = useCurrentUser();
  const [theme, switchTheme] = useTheme();
  const logout = useLogout();

  const [openDropdown, setOpenDropdown] = useState(false);
  const [openTokenDialog, setOpenTokenDialog] = useState(false);

  const isLightTheme = theme === THEMES.LIGHT;

  const items: MenuProps['items'] = [
    {
      key: 'theme',
      icon: <BulbFilled />,
      onClick: () => switchTheme(isLightTheme ? THEMES.DARK : THEMES.LIGHT),
      label: `Use ${isLightTheme ? 'Dark' : 'Light'} Theme`
    },
    {
      key: 'token',
      icon: <LockOutlined />,
      label: 'Create Functional Token',
      onClick: () => setOpenTokenDialog(true)
    },
    {
      key: 'logout',
      icon: <LogoutOutlined />,
      label: 'Logout',
      onClick: logout
    }
  ];

  return (
    <>
      <Dropdown arrow menu={{ items }} onOpenChange={(o) => setOpenDropdown(o)} trigger={['click']}>
        <Tooltip title={openDropdown ? '' : currentUser.name} trigger={['click', 'hover']} placement="bottomLeft">
          <StyledButton icon={<UserMenuIcon />} type="text" />
        </Tooltip>
      </Dropdown>

      <ExternalTokenDialog open={openTokenDialog} setOpen={setOpenTokenDialog} />
    </>
  );
};
