import { useState } from 'react';
import { Button, Descriptions, Tooltip } from 'antd';
import { FileSearchOutlined } from '@ant-design/icons';
import { Comparator } from '../../../domain';
import { ShiftedDrawer } from '../../shared/components/ShiftedDrawer';
import { camelCaseToName, formatValue } from '../shared/util';
import { RevisionMeta, SourceMeta } from '../../../api/report/domain/types';

export const ReportMetadata = (props: { source?: SourceMeta; revision?: RevisionMeta; disabled?: boolean; loading: boolean }) => {
  const extra = [
    ...Object.keys(props.revision ?? {})
      .filter((k) => k !== 'extra')
      .map((k) => ({
        name: camelCaseToName(k),
        value: formatValue((props.revision as any)[k].toString())
      }))
  ].sort((a, b) => Comparator.lexicographicalComparison(a.name, b.name));

  const [visible, setVisible] = useState(false);

  return (
    <>
      <ShiftedDrawer title="Details" open={visible} onClose={() => setVisible(false)}>
        <Descriptions column={1} bordered>
          {extra.map((b) => (
            <Descriptions.Item key={`meta-${b.name}`} label={b.name}>
              {b.value}
            </Descriptions.Item>
          ))}
        </Descriptions>
      </ShiftedDrawer>

      <Tooltip title="Show Details">
        <Button onClick={() => setVisible(true)} icon={<FileSearchOutlined />} loading={props.loading} disabled={props.disabled}>
          Details
        </Button>
      </Tooltip>
    </>
  );
};
