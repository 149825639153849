import _ from 'lodash';
import { useState } from 'react';
import { Button, Descriptions, Divider, Tooltip } from 'antd';
import { FileSearchOutlined } from '@ant-design/icons';
import { ShiftedDrawer } from '../../shared/components/ShiftedDrawer';
import { Document, RevisionMeta, SourceMeta } from '../../../api/report/domain/types';
import { formatValue, valueFromObjectPath } from '../shared/util';
import { ColumnType } from '../hooks/useFlatifiedReports';
import { MetricOnVisible } from '../../../contexts/metrics/components/MetricOnVisible';
import { MetricViewIds } from '../../../contexts/metrics/constants';
import { reportDocumentToTags, reportMetaToTags } from '../../../contexts/metrics/utils/mapping';

export const DocumentDetails = (props: { source: SourceMeta; revision: RevisionMeta; document: Document; columns: ColumnType[] }) => {
  const [visible, setVisible] = useState(false);

  if (props.columns.length < 1) {
    return null;
  }

  const grouped = _.groupBy(props.columns, (c) => c.group);
  const groupedArray = Object.keys(grouped).map((key) => ({
    group: key,
    columns:
      grouped[key]?.map((column) => ({
        column,
        value: valueFromObjectPath<string>(column.path)(props.document)
      })) || []
  }));

  const content = groupedArray.map((c) => (
    <div key={c.group}>
      <Divider style={{ marginTop: 0 }}>{c.group}</Divider>
      <Descriptions style={{ marginBottom: 16 }} labelStyle={{ width: '30%' }} column={1} bordered>
        {c.columns.map((b) => (
          <Descriptions.Item key={`meta-${b.column.id}`} label={b.column.name}>
            {formatValue(b.value)}
          </Descriptions.Item>
        ))}
      </Descriptions>
    </div>
  ));

  return (
    <>
      <ShiftedDrawer 
        title="Report Details"
        open={visible} 
        onClose={() => setVisible(false)}
      >
        <MetricOnVisible
          view={MetricViewIds.reportDetails}
          payload={[...reportMetaToTags(props.source, props.revision), ...reportDocumentToTags(props.document)]}
        />
        <div style={{ overflowY: 'auto', overflowX: 'hidden', paddingTop: 8 }}>{content}</div>
      </ShiftedDrawer>

      <Tooltip title="Show Report Details">
        <Button type="text" id="report-tour-element7" onClick={() => setVisible(true)} icon={<FileSearchOutlined />} />
      </Tooltip>
    </>
  );
};
